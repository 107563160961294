// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import {AuthenticatedUser} from '@admin-tribe/acsc';
import {Button, Flex, Heading, Text, View} from '@adobe/react-spectrum';
import ChevronUp from '@spectrum-icons/workflow/ChevronUp';
import Launch from '@spectrum-icons/workflow/Launch';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import styles from './RecommendedFeedTriggerButton.pcss';

/**
 * RecommendedFeedTriggerButton component renders a button that triggers a drawer.
 * The button renders as a floating panel on the bottom left of the screen.
 * The button includes a tag, heading, description, and an icon.
 * Button tag is localized content personalized with the user's first name.
 * Button heading is a localized string.
 * Button description is localized content personalized with the organization's name.
 * Button icon is a gradient colored Launch icon.
 * @param {Object} props - The properties object.
 * @param {Function} props.triggerDrawer - The function to trigger the drawer.
 *
 * @returns {JSX.Element} The rendered RecommendedFeedTriggerButton component.
 */
const RecommendedFeedTriggerButton = ({triggerDrawer}) => {
  const intl = useIntl();

  const currentUser = AuthenticatedUser.get();
  const currentUserName = currentUser.getFirstName();
  const currentOrgName = rootStore.organizationStore.activeOrg.name;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events -- Click event is handled by the onClick prop
    <div
      // Required to make the button pressable
      aria-label="Open recommended feed"
      data-testid="one-console-promo-trigger-button"
      onClick={triggerDrawer}
      role="button"
      tabIndex={0}
    >
      <View
        data-testid="one-console-promo-trigger"
        // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- Moved styling to external file
        UNSAFE_className={styles.recommendedfeedtriggerbutton}
      >
        {/* Adding the button element gives us styling/hover over for the chevron icon */}
        <Button
          aria-label="Open recommended feed"
          data-testid="one-console-promo-trigger-chevron"
          id="one-console-promo-trigger-chevron"
          onPress={triggerDrawer}
          tabIndex={-1}
          // eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- Moved styling to external file
          UNSAFE_className={styles.recommendedfeedtriggerchevron}
          variant="secondary"
        >
          <ChevronUp flex="none" />
        </Button>
        <Flex alignItems="center" direction="row">
          <View flex="none" height="size-550" marginEnd="size-200" width="size-400">
            {/* Applies a gradient to the workflow icon SVG. */}
            <svg height="100%" width="100%">
              <defs>
                {/* eslint-disable-next-line react/forbid-dom-props -- id is required to apply the svg styling */}
                <linearGradient id="rssGradient" x1="0%" x2="100%" y1="0%" y2="100%">
                  <stop offset="0%" stopColor="#D73220" />
                  <stop offset="33%" stopColor="#D92361" />
                  <stop offset="100%" stopColor="#7155FA" />
                </linearGradient>
              </defs>
              <Launch UNSAFE_style={{fill: 'url(#rssGradient)'}} />
            </svg>
          </View>
          <Flex direction="column">
            <View>
              <Text
                data-testid="one-console-promo-trigger-tag"
                marginBottom="size-0"
                marginTop="size-0"
                UNSAFE_style={{
                  color: 'var(--spectrum-global-color-blue-500)',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
              >
                {intl.formatMessage(
                  {id: 'shell.recommendedFeed.triggerTag'},
                  {name: currentUserName}
                )}
              </Text>
              <Heading
                data-testid="one-console-promo-trigger-heading"
                level={2}
                marginBottom="size-0"
                marginTop="size-0"
              >
                {intl.formatMessage({id: 'shell.recommendedFeed.triggerHeader'})}
              </Heading>
              <Text data-testid="one-console-promo-trigger-description" minWidth={0}>
                {intl.formatMessage(
                  {id: 'shell.recommendedFeed.triggerDescription'},
                  {orgName: currentOrgName}
                )}
              </Text>
            </View>
          </Flex>
        </Flex>
      </View>
    </div>
  );
};

RecommendedFeedTriggerButton.propTypes = {
  triggerDrawer: PropTypes.func.isRequired,
};

export default RecommendedFeedTriggerButton;
