import {Button, Flex, Heading, Image, Text} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import React from 'react';
import {useIntl} from 'react-intl';

import RosterSyncIcon from 'features/settings/assets/group_meet_roster_sync.svg';

const SetupRosterSync = () => {
  const intl = useIntl();

  return (
    <Flex alignItems="center" direction="column">
      <Image
        alt={intl.formatMessage({id: 'settings.rosterSync.setup.icon.alt'})}
        src={RosterSyncIcon}
      />
      <Heading level={2} marginTop="size-0">
        {intl.formatMessage({id: 'settings.rosterSync.setup.title'})}
      </Heading>
      <Text>
        {intl.formatMessage({
          id: 'settings.rosterSync.setup.description',
        })}
      </Text>
      <Text>
        <GoUrl name="aac_roster_sync">
          {intl.formatMessage({
            id: 'settings.rosterSync.setup.learnMore',
          })}
        </GoUrl>
      </Text>
      <Button
        marginTop="size-200"
        onPress={
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- this is a placeholder function
          // istanbul ignore next -- not testing this
          () => {
            // Open the popup to setup roster sync when it's implemented
          }
        }
        variant="cta"
      >
        {intl.formatMessage({id: 'settings.rosterSync.setup.button'})}
      </Button>
    </Flex>
  );
};

export default SetupRosterSync;
