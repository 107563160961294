// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {INTERACTION, hasDomain, log} from '@admin-tribe/acsc';
import {Button, Flex} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import {getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';
import useGlobalModal from 'common/hooks/useGlobalModal';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

const HELP_SEARCH_ALLOWLIST_DOMAINS = ['adobe.com', 'frame.io'];

/**
 * SophiaPromoActions component.
 *
 * This component renders primary and secondary call-to-action (CTA) buttons based on the provided payload.
 * It validates the URLs to ensure they belong to allowed domains and handles button click events.
 *
 * @component
 * @returns {JSX.Element|null} The rendered component or null if no valid CTAs are provided.
 */
const SophiaPromoActions = ({onPressAction}) => {
  const {payload, analytics} = useJsonPayload();

  /**
   * Validates if the given URL belongs to an allowed domain.
   *
   * @param {string} url - The URL to validate.
   * @returns {boolean} True if the URL is valid, false otherwise.
   */
  const isValidAdobeUrl = (url) => {
    if (url) {
      if (HELP_SEARCH_ALLOWLIST_DOMAINS.some((domain) => hasDomain(url, domain))) {
        return true;
      }
      log.error(`Invalid non-Adobe domain: ${url}`);
    } else {
      log.error('Sophia card given undefined or empty URL');
    }
    return false;
  };

  const [modalComponent, setModalComponent] = useState(null);
  const {openModal} = useGlobalModal(modalComponent);

  if (!payload) {
    return null;
  }

  const primaryCTAAction = payload.primaryCTAAction;
  const primaryCTALabel = payload.primaryCTALabel;
  const primaryVariant = payload.primaryCTAVariant || 'accent';
  const secondaryCTAAction = payload.secondaryCTAAction;
  const secondaryCTALabel = payload.secondaryCTALabel;

  const shouldShowButton = (label, url) => label && isValidAdobeUrl(url);
  const primaryCTACallback = getCtaClickEventCallback(primaryCTAAction);
  const secondaryCTACallback = getCtaClickEventCallback(secondaryCTAAction);

  const showPrimaryButton = shouldShowButton(primaryCTALabel, primaryCTAAction);
  const showSecondaryButton = shouldShowButton(secondaryCTALabel, secondaryCTAAction);

  if (!showPrimaryButton && !showSecondaryButton) {
    return null;
  }

  /**
   * Handles the primary action button press event.
   */
  const onPrimaryActionPress = () => {
    if (onPressAction) {
      onPressAction();
    }
    sophiaHelper.dispatchSophiaPromoInteractEvent({
      analytics,
      ctaAction: INTERACTION.PRIMARY,
    });
    primaryCTACallback?.();
    setModalComponent(primaryCTACallback?.());
    openModal();
  };

  /**
   * Handles the secondary action button press event.
   */
  const onSecondaryActionPress = () => {
    if (onPressAction) {
      onPressAction();
    }
    sophiaHelper.dispatchSophiaPromoInteractEvent({
      analytics,
      ctaAction: INTERACTION.SECONDARY,
    });
    secondaryCTACallback?.();
  };

  return (
    <Flex
      alignItems="start"
      data-testid="one-console-promo-actions"
      direction={{base: 'column', M: 'row'}}
      gap="size-200"
    >
      {showSecondaryButton && (
        <Button
          data-testid="one-console-secondary-cta-button"
          height="auto"
          onPress={onSecondaryActionPress}
          variant="secondary"
        >
          {secondaryCTALabel}
        </Button>
      )}
      {showPrimaryButton && (
        <Button
          data-testid="one-console-primary-cta-button"
          height="auto"
          onPress={onPrimaryActionPress}
          // eslint-disable-next-line react/style-prop-object -- required due to false positive on style prop for Spectrum Button
          style="fill"
          variant={primaryVariant}
        >
          {primaryCTALabel}
        </Button>
      )}
    </Flex>
  );
};

SophiaPromoActions.propTypes = {
  onPressAction: PropTypes.func,
};

export default SophiaPromoActions;
