// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Flex, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SophiaPromoActions from 'common/components/sophia/sophia-promo/sophia-promo-actions/SophiaPromoActions';
import SophiaPromoCloseButton from 'common/components/sophia/sophia-promo/sophia-promo-close-button/SophiaPromoCloseButton';
import SophiaPromoDescription from 'common/components/sophia/sophia-promo/sophia-promo-description/SophiaPromoDescription';
import SophiaPromoIcon from 'common/components/sophia/sophia-promo/sophia-promo-icon/SophiaPromoIcon';
import SophiaPromoBasePrice from 'common/components/sophia/sophia-promo/sophia-promo-prices/SophiaPromoBasePrice';
import SophiaPromoDiscountedPrice from 'common/components/sophia/sophia-promo/sophia-promo-prices/SophiaPromoDiscountedPrice';
import SophiaPromoTag from 'common/components/sophia/sophia-promo/sophia-promo-tag/SophiaPromoTag';
import SophiaPromoTermsAndConditionsDialogTrigger from 'common/components/sophia/sophia-promo/sophia-promo-terms-and-conditions/SophiaPromoTermsAndConditionsDialogTrigger';
import SophiaPromoTermsAndConditionsHoverOverTooltip from 'common/components/sophia/sophia-promo/sophia-promo-terms-and-conditions/SophiaPromoTermsAndConditionsHoverOverTooltip';
import SophiaPromoTitle from 'common/components/sophia/sophia-promo/sophia-promo-title/SophiaPromoTitle';

import {hasPayloadPricing} from './sophia-promo-prices/util';

// eslint-disable-next-line complexity -- component is complex as it is reused between panel and banner modes
const SophiaPromoBannerPanel = ({onDismiss}) => {
  const intl = useIntl();

  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  // https://www.figma.com/proto/gtaOcMgCcide4uyKfVvXnB/AC---Products-Page---HVA-Banner?page-id=401%3A43&node-id=1539-938&node-type=frame&viewport=-3386%2C174%2C0.24&t=Z4iZlSKtbfCoTmlK-1&scaling=contain&content-scaling=fixed
  // At M breakpoint, if there is pricing it is left aligned, if no pricing info it is right aligned
  const hasPricing = hasPayloadPricing(payload);
  // If there is a tag, then content needs pushed down
  const hasTag = payload.tag && payload.badgeType;
  // Adjust some styles depending on payload content
  const dynamicStyles = {
    bannerContentMarginTopM: hasTag ? 'size-100' : '-5px',
    bannerHeaderMarginTopM: hasTag ? 'size-300' : 'size-150',
    bottomRowJustifyContentM: hasPricing ? 'start' : 'end',
    bottomRowMarginStartM: hasPricing ? 'size-900' : 'size-0',
    pricingContainerMarginEnd: hasPricing ? 'size-150' : 'size-0',
  };

  const showLink = payload.termsAndConditions && payload.termsHeading;

  const showPriceFrequency = !!payload.priceFrequency;
  const displayExcludingVAT = !!payload.displayExcludingVAT;

  const priceFrequencyLabel = displayExcludingVAT
    ? intl.formatMessage({id: 'common.sophia.sophiaPromo.pricing.perLicenseExcludingVAT.label'})
    : intl.formatMessage({id: 'common.sophia.sophiaPromo.pricing.perLicense.label'});

  return (
    <View
      borderColor="gray-300"
      borderRadius="regular"
      borderWidth="thin"
      data-testid="one-console-promo-banner"
      padding="size-250"
      position="relative"
      UNSAFE_style={{
        backgroundColor: payload.backgroundColor,
        boxShadow: '0 0.1875rem 0.375rem rgba(0,0,0,.08)',
      }}
      width="100%"
    >
      <Flex alignItems="start" direction={{base: 'column', XL: 'row'}} marginTop="-10px" wrap>
        <Flex direction={{base: 'column', M: 'row'}} width={{base: '85%', XL: '65%'}}>
          <Flex
            data-testid="one-console-promo-banner-header"
            marginBottom={{base: 'size-150', M: 'size-0'}}
            marginTop={{base: 'size-0', M: dynamicStyles.bannerHeaderMarginTopM}}
          >
            <SophiaPromoIcon />
          </Flex>
          <Flex
            alignItems="start"
            data-testid="one-console-promo-banner-content"
            direction="column"
            marginStart={{base: 'size-0', M: 'size-200'}}
            marginTop={{base: 'size-0', M: dynamicStyles.bannerContentMarginTopM}}
          >
            <Flex
              marginBottom={{base: 'size-0', M: 'size-150'}}
              marginStart={{base: 'size-900', M: 'size-0'}}
            >
              <SophiaPromoTag mode="banner" />
            </Flex>
            <SophiaPromoTitle />
            <SophiaPromoDescription>
              <SophiaPromoTermsAndConditionsHoverOverTooltip mode="banner" />
            </SophiaPromoDescription>
            {showLink && (
              <View marginTop="-18px">
                <SophiaPromoTermsAndConditionsDialogTrigger mode="banner" />
              </View>
            )}
          </Flex>
        </Flex>

        <Flex
          alignItems={{base: 'start', M: 'end'}}
          alignSelf={{base: 'center'}}
          data-testid="one-console-promo-banner-price"
          direction={{base: 'column', M: 'row'}}
          gap={{base: 'size-150'}}
          justifyContent={{base: 'start', M: dynamicStyles.bottomRowJustifyContentM, XL: 'end'}}
          marginStart={{base: 'size-0', M: dynamicStyles.bottomRowMarginStartM, XL: 'size-0'}}
          marginTop={{base: 'size-250', XL: 'size-0'}}
          width={{base: '100%', XL: '35%'}}
        >
          <Flex
            alignItems={{base: 'start', XL: 'end'}}
            direction="column"
            marginEnd={dynamicStyles.pricingContainerMarginEnd}
          >
            <SophiaPromoBasePrice mode="banner" />
            <SophiaPromoDiscountedPrice />
            {showPriceFrequency && (
              <Text UNSAFE_style={{fontStyle: 'var(--spectrum-global-font-style-italic,italic)'}}>
                {priceFrequencyLabel}
              </Text>
            )}
          </Flex>
          <View
            data-testid="one-console-promo-banner-actions"
            marginBottom="size-100"
            marginEnd="size-200"
          >
            <SophiaPromoActions />
          </View>
        </Flex>

        <SophiaPromoCloseButton
          data-testid="one-console-promo-banner-close"
          onDismiss={onDismiss}
        />
      </Flex>
    </View>
  );
};
// eslint-enable-next-line complexity -- component is complex as it is reused between panel and banner modes

SophiaPromoBannerPanel.propTypes = {
  onDismiss: PropTypes.func,
};

export default SophiaPromoBannerPanel;
