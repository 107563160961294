// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import PropTypes from 'prop-types';
import React from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SophiaPromoRecommendationPanel from 'common/components/sophia/sophia-promo/SophiaPromoRecommendationPanel';
import SophiaPromoRightRailPanel from 'common/components/sophia/sophia-promo/SophiaPromoRightRailPanel';
import {RecommendationType} from 'common/services/sophia/utils/utils';

/**
 * SophiaPromoRecommendationFeedCard component. This is a panel that is used in the recommendation section on the shell,
 * one of a set of related panels that can contain different combinations of the child components, depending
 * on the type of panel. This will delegate the actual rendering to the new recommendation-feed cards, or the right-rail
 * style cards, depending on the recommendation type.
 *
 * @returns {Element} the SophiaPromoRecommendationPanel component or the SophiaPromoRightRailPanel component.
 * @constructor SophiaPromoRecommendationFeedCard component
 */
const SophiaPromoRecommendationFeedCard = ({onCardCTAAction}) => {
  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  const promoStyle =
    payload.recommendationType === RecommendationType.PromotionInfoCard ||
    payload.recommendationType === RecommendationType.PromotionOfferCard ||
    payload.recommendationType === RecommendationType.PromotionRecommendationCard;

  return promoStyle ? (
    <SophiaPromoRightRailPanel onCardCTAAction={onCardCTAAction} />
  ) : (
    <SophiaPromoRecommendationPanel onCardCTAAction={onCardCTAAction} />
  );
};

SophiaPromoRecommendationFeedCard.propTypes = {
  onCardCTAAction: PropTypes.func,
};

export default SophiaPromoRecommendationFeedCard;
