// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import {EVENT_ACTION, dispatchUiEventAnalytics, log} from '@admin-tribe/acsc';
import {Flex} from '@adobe/react-spectrum';
import {DrawerTrigger} from '@pandora/react-drawer';
import React, {useCallback, useEffect, useState} from 'react';

import {JsonPayloadProvider} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SophiaPromoRecommendationFeedCard from 'common/components/sophia/sophia-promo/SophiaPromoRecommendationFeedCard';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {mapPromoRecommendationContent} from 'common/services/sophia/utils/utils';
import useLocationChange from 'shell/hooks/useLocationChange';

import RecommendedFeedDrawer from './recommended-feed-drawer/RecommendedFeedDrawer';
import RecommendedFeedTriggerButton from './recommended-feed-trigger-button/RecommendedFeedTriggerButton';

/**
 * RecommendedFeed component, see https://jira.corp.adobe.com/browse/ONESIE-43978
 * This component is responsible for rendering the Recommended Feed drawer and trigger button, as well as fetching p13n content for the drawer content.
 * The drawer contains promotional cards and is triggered by the button.
 * The button/trigger is a fixed position button that is always visible on the screen. It floats above the content and triggers the drawer to open.
 * Visibility of the trigger button is determined by the p18n content response.
 *
 * @returns {JSX.Element} The rendered RecommendedFeed component.
 */
const RecommendedFeed = () => {
  const [currentLocation, setCurrentLocation] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [sophiaContent, setSophiaContent] = useState();
  const [loadedLocation, setLoadedLocation] = useState();

  const surfaceId = 'one_console_recommended_feed';

  const {currentLocationPathname} = useLocationChange();

  // Set the current location based on the path
  useEffect(() => {
    if (!currentLocationPathname) {
      return;
    }

    // /:orgid/overview?flags="foo,bar,foobar"
    //            ^ targeting this
    const currentRouteRootWithParamsRemoved = currentLocationPathname
      .split('/')?.[2]
      ?.split('?')?.[0];

    if (currentRouteRootWithParamsRemoved === currentLocation) {
      return;
    }

    setCurrentLocation(currentRouteRootWithParamsRemoved);
  }, [currentLocationPathname, currentLocation]);

  // call sophia to get the content
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(undefined);

      const contextualParamsOptions = {
        recommendedFeedLocation: currentLocation,
      };

      try {
        // Resolve the initial payload
        const sophiaPayload = await sophiaHelper.getSophiaContent({
          contextualParamsOptions,
          surfaceId,
        });

        // if no payload content, do nothing, component will not render
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- Tests have coverage for this but the report is failing to show it
        // istanbul ignore next
        if (
          !sophiaPayload ||
          !Array.isArray(sophiaPayload.items) ||
          !sophiaPayload.items[0]?.content
        ) {
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- Tests have coverage for this but the report is failing to show it
          // istanbul ignore next
          return;
        }
        // get the payload data
        const parsedSophiaContent = sophiaPayload.items.map((item) => {
          const payloadJsonContent = JSON.parse(item.content);

          const parsedContent = mapPromoRecommendationContent(payloadJsonContent);
          // Dispatch the load event for each card using the analytics ID from the payload
          const analytics = sophiaHelper.getSophiaPromoAnalytics(item, parsedContent);
          sophiaHelper.dispatchSophiaPromoLoadEvent(analytics, 'recommendedFeed');
          return {
            analytics,
            content: parsedContent,
            uniqueKey: parsedContent.analyticsId,
          };
        });

        setSophiaContent(parsedSophiaContent);
      } catch (error_) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- Tests have coverage for this but the report is failing to show it
        // istanbul ignore next
        setError(error_);
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- Tests have coverage for this but the report is failing to show it
        // istanbul ignore next
        log.error('Error resolving or transforming payload:', error_);
      } finally {
        setLoadedLocation(currentLocation);
        setLoading(false);
      }
    };

    // Fetch the data if it hasn't been loaded yet or if the location has changed.
    const shouldFetch = !loading && !error && currentLocation !== loadedLocation;
    if (shouldFetch) {
      setSophiaContent(undefined);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run this effect when the location changes
  }, [surfaceId, currentLocation]);

  let openDrawerFn;

  const triggerDrawer = useCallback(() => {
    openDrawerFn();

    const renderedCardAnalytics = sophiaContent.map((contentItem) => contentItem.analytics);

    // Send click event for the drawer trigger button, event name / payload TBC with enablement
    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: 'recommendedFeed:openDrawer',
      renderedCards: renderedCardAnalytics,
    });

    // For each shown card, send a display event
    // This has to happen on click since the cards are not shown until the drawer is opened
    renderedCardAnalytics.forEach((analytics) => {
      dispatchUiEventAnalytics({
        card: analytics,
        eventAction: EVENT_ACTION.DISPLAY,
        eventName: 'recommendedFeed:cardDisplayed',
      });
    });
  }, [openDrawerFn, sophiaContent]);

  const onCloseDrawer = () => {
    const renderedCardAnalytics = sophiaContent.map((contentItem) => contentItem.analytics);

    // Send click event on close of the drawer, event name / payload TBC with enablement
    dispatchUiEventAnalytics({
      eventAction: EVENT_ACTION.CLICK,
      eventName: 'recommendedFeed:closeDrawer',
      renderedCards: renderedCardAnalytics,
    });
  };

  const renderDrawerBody = (onCardCTAAction) => (
    <Flex direction="column" gap="size-300">
      {sophiaContent?.map((contentItem) => (
        <JsonPayloadProvider
          key={contentItem.uniqueKey}
          analytics={contentItem.analytics}
          payload={contentItem.content}
        >
          <SophiaPromoRecommendationFeedCard onCardCTAAction={onCardCTAAction} />
        </JsonPayloadProvider>
      ))}
    </Flex>
  );

  const onCardCTAAction = (closeDrawer) => {
    closeDrawer();
    onCloseDrawer();
  };

  return (
    <>
      <DrawerTrigger
        alignment="left"
        animationDirection="vertical"
        drawerContentPaddingY="size-0"
        isDismissable={false}
        offsetBottom="var(--spectrum-global-dimension-size-600)"
        paddingX="0px"
        paddingY="0px"
      >
        {/* Intercept the function to open the drawer */}
        {(openDrawerFnFromTrigger) => {
          openDrawerFn = openDrawerFnFromTrigger;
        }}
        {(close) => (
          <RecommendedFeedDrawer closeDrawer={close} onCloseDrawer={onCloseDrawer}>
            {renderDrawerBody(() => onCardCTAAction(close))}
          </RecommendedFeedDrawer>
        )}
      </DrawerTrigger>
      {!loading && !error && sophiaContent && currentLocation === loadedLocation && (
        // Send display event on render of the trigger button, event name / payload TBC with enablement
        <RecommendedFeedTriggerButton triggerDrawer={triggerDrawer} />
      )}
    </>
  );
};

export default RecommendedFeed;
