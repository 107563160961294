import {Page, PageContent, PageHeader} from '@admin-tribe/acsc-ui';
import {Divider, Flex, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';
import {useLoaderData} from 'react-router-dom';

import LegacyRosterSyncError from 'features/settings/components/roster-sync-settings/LegacyRosterSyncError';
import SetupRosterSync from 'features/settings/components/roster-sync-settings/SetupRosterSync';

/**
 * Defines the roster sync settings page under the settings tab.
 */
const RosterSyncSettingsPage = () => {
  const intl = useIntl();
  const {rosterSyncs} = useLoaderData();
  const hasLegacyRosterSync = rosterSyncs?.length > 0;

  return (
    <Page data-testid="roster-sync-settings-page">
      <PageHeader
        title={intl.formatMessage({
          id: 'settings.rosterSync.title',
        })}
      />
      <PageContent>
        <View>
          <Divider size="S" />
        </View>
        <Flex alignItems="center" justifyContent="center" marginTop="size-600">
          {hasLegacyRosterSync ? <LegacyRosterSyncError /> : <SetupRosterSync />}
        </Flex>
      </PageContent>
    </Page>
  );
};

export default RosterSyncSettingsPage;
