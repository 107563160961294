// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';

import {useJsonPayload} from '../JsonPayloadProvider';

import RegularPriceInfo from './regular-price-info/RegularPriceInfo';
import StrikethroughPriceInfo from './striketrhough-price-info/StrikethroughPriceInfo';

const SophiaPromoBasePrice = ({mode = 'right-rail'}) => {
  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  if (!payload.regularPrice || !payload.priceFrequency) {
    return null;
  }

  if (payload.discountedPrice) {
    return (
      <View data-testid="one-console-promo-base-price">
        <StrikethroughPriceInfo
          data-testid="one-console-promo-base-price-strikethrough"
          frequency={payload.priceFrequency}
          price={payload.regularPrice}
          strikethroughColor={payload.strikethroughColor} // defaults to black if not passed
        />
      </View>
    );
  }

  return (
    <View data-testid="one-console-promo-base-price" marginBottom={{base: 'size-100', M: 'none'}}>
      <RegularPriceInfo
        data-testid="one-console-promo-base-price-regular"
        displayExcludingVAT={payload.displayExcludingVAT}
        displayPriceStartingAt={payload.displayPriceStartingAt}
        frequency={payload.priceFrequency}
        mode={mode}
        price={payload.regularPrice}
      />
    </View>
  );
};

SophiaPromoBasePrice.propTypes = {
  mode: PropTypes.string,
};

export default SophiaPromoBasePrice;
