// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

/**
 * SophiaPromoVideoDisplay component.
 *
 * This component displays a video based on the provided payload.
 * If the payload or videoSourceUrl is missing, it returns null.
 * The video is displayed with the `objectFit="cover"` setting, which ensures that the video covers the entire area of
 * the container while maintaining its aspect ratio.
 * The `jsx-a11y/media-has-caption` rule is disabled for proof of concept, but a follow-up action will add captions for accessibility.
 * This will be followed up with support for captions and/or audio tracks.
 *
 * @component
 * @returns {JSX.Element|null} The rendered component or null if no videoSourceUrl is provided.
 */
const SophiaPromoVideoDisplay = () => {
  const intl = useIntl();
  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  if (!payload.videoSourceUrl) {
    return null;
  }

  const videoType = payload.videoMimeType || 'video/mp4';

  const videoNoSupportLabel = intl.formatMessage({
    id: 'common.sophia.sophiaPromo.video.noSupport.label',
  });

  return (
    <View data-testid="one-console-promo-media-display" paddingBottom="size-100">
      {/* eslint-disable jsx-a11y/media-has-caption -- this is a T&O proof of concept, will follow-up in later PR */}
      {payload.videoSourceUrl && (
        <video
          controls
          data-testid="one-console-promo-media-display-video"
          height="100%"
          poster={payload.videoPosterUrl}
          // eslint-disable-next-line react/forbid-dom-props -- applied to avoid adding a pcss file for this small component
          style={{objectFit: 'cover'}}
          width="100%"
        >
          <source
            data-testid="one-console-promo-media-display-video-source"
            src={payload.videoSourceUrl}
            type={videoType}
          />
          {videoNoSupportLabel}
        </video>
      )}
      {/* eslint-enable jsx-a11y/media-has-caption -- re-enabling */}
    </View>
  );
};

SophiaPromoVideoDisplay.propTypes = {};

export default SophiaPromoVideoDisplay;
