// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************

import {Button, Content, Flex, Header, Heading, Text, View} from '@adobe/react-spectrum';
import {Drawer} from '@pandora/react-drawer';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import Launch from '@spectrum-icons/workflow/Launch';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

/**
 * Renders a drawer with a heading and content.
 * The heading includes an icon and a localized heading.
 *
 * @param {React.ReactNode} props.children - The content to be displayed inside the drawer.
 * @param {Function} props.closeDrawer - The function to close the drawer.
 * @param {Function} props.onCloseDrawer - The function to call when the drawer is closed.
 *
 * @returns {JSX.Element} The rendered RecommendedFeedDrawer component.
 */
const RecommendedFeedDrawer = ({children, closeDrawer, onCloseDrawer}) => {
  const intl = useIntl();

  const closeDrawerAndTrack = () => {
    closeDrawer();
    onCloseDrawer();
  };

  return (
    <Drawer aria-label="recommended-feed-drawer" id="recommendedFeedDrawer">
      <View backgroundColor="static-white" height="size-800" padding="size-225" width="100%">
        <Header>
          <Heading level={2} marginBottom="size-0" marginTop="size-0">
            <Flex alignItems="center" direction="row">
              <View
                data-testid="drawer-header-icon"
                flex="none"
                height="size-300"
                marginEnd="size-200"
                width="size-400"
              >
                {/* Applies a gradient to the workflow icon SVG. */}
                <svg height="100%" width="100%">
                  <defs>
                    {/* eslint-disable-next-line react/forbid-dom-props -- id is required to apply the svg styling */}
                    <linearGradient id="rssGradient" x1="0%" x2="100%" y1="0%" y2="100%">
                      <stop offset="0%" stopColor="#D73220" />
                      <stop offset="33%" stopColor="#D92361" />
                      <stop offset="100%" stopColor="#7155FA" />
                    </linearGradient>
                  </defs>
                  <Launch UNSAFE_style={{fill: 'url(#rssGradient)'}} />
                </svg>
              </View>
              <View data-testid="drawer-header-text" paddingStart="size-115">
                <Text>{intl.formatMessage({id: 'shell.recommendedFeed.drawerHeader'})}</Text>
              </View>
              <Button
                data-testid="drawer-header-close"
                height="size-400"
                onClick={closeDrawerAndTrack}
                UNSAFE_style={{
                  marginLeft: 'auto',
                  minInlineSize: 'auto',
                }}
                variant="primary"
                width="size-400"
              >
                <ChevronDown flex="none" />
              </Button>
            </Flex>
          </Heading>
        </Header>
      </View>
      <Content
        data-testid="drawer-content"
        height="100vh"
        id="drawer-content"
        UNSAFE_style={{
          backgroundColor: 'var(--spectrum-global-color-gray-200)',
          paddingBottom: 'var(--spectrum-global-dimension-size-0) !important',
        }}
      >
        <View height="100%" overflow="auto" padding="size-300">
          {children}
        </View>
      </Content>
    </Drawer>
  );
};

RecommendedFeedDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  onCloseDrawer: PropTypes.func.isRequired,
};

export default RecommendedFeedDrawer;
