import {
  CONTRACT_COMPLIANCE_SYMPTOMS,
  CONTRACT_EXPIRATION_PHASE,
  EVENT_ACTION,
  EVENT_NAME,
  dispatchUiEventAnalytics,
  feature,
  getAllocationContracts,
  getExpirationPhaseFromContractComplianceSymptoms as getExpirationPhaseFromComplianceSymptoms,
  getNewVipContract,
  log,
} from '@admin-tribe/acsc';

import rootStore from 'core/RootStore';
import {canViewAccountOverviewPage} from 'core/account/access/accountAccess';
import {ACCOUNT_STATE_PARAM} from 'core/account/account-meta/AccountMetaConstants';
import trialHelper from 'core/products/trial-helper/trialHelper';
import auth from 'core/services/auth';
import {GLOBAL_BANNER_TYPES} from 'features/global-banner/GlobalBannerConstants';

import {canDisplayContract} from '../access/contractAccess';

const ExpirationPhaseToImpression = {
  [CONTRACT_EXPIRATION_PHASE.NOTIFICATION]: 'Notification',
  [CONTRACT_EXPIRATION_PHASE.GRACE]: 'Grace',
  [CONTRACT_EXPIRATION_PHASE.POST_GRACE]: 'PostGrace',
  [CONTRACT_EXPIRATION_PHASE.INACTIVE]: 'Inactive',
};

/**
 * @description Method called by the main boot service to generate the global banners for contract state. Only System Admins see these banners.
 */
function notifyContractStateBanners() {
  if (rootStore.organizationStore.contractList.items.length > 0) {
    if (auth.isUserOrgAdmin() || auth.isUserContractAdmin()) {
      notifyForAllocationContracts();
      notifyForEtlaContractState();
      notifyForNewVipContract();
    }
  }
}

//////////

/**
 * @description Method for iterating over the allocation contracts for an
 *     org and if they are in expiration phase we will display a banner.
 */
function notifyForAllocationContracts() {
  const contractList = rootStore.organizationStore.contractListIncludingInactive;

  const allocationContracts = getAllocationContracts(contractList);
  allocationContracts.forEach((contract) => {
    const banner = getBannerForExpirationPhase(contract);
    if (banner) {
      rootStore.organizationStore.globalBannerStore.add(banner);
    }
  });
}

function notifyForEtlaContractState() {
  const contractList = rootStore.organizationStore.contractListIncludingInactive;

  const addBannersForEtlaContracts = (etlaContracts) => {
    etlaContracts.forEach((etlaContract) => {
      const banner = getBannerForExpirationPhase(etlaContract);
      if (banner) {
        rootStore.organizationStore.globalBannerStore.add(banner);
      }
    });
  };

  if (feature.isEnabled('temp_pa2881_multiple_contracts')) {
    const etlaContracts = contractList.items
      .filter((contract) => contract.isBuyingProgramETLA() && canDisplayContract(contract))
      .sort((a, b) => a.getEndDate() - b.getEndDate());
    // The Global Banner store currently displays banners in the order they were inserted, provided the priority and type match.
    // However, since the Global Banner does not have the ability to sort banners by end date, we pass the banners in ascending order of their contract end dates when dealing with multiple ETLA contracts.
    addBannersForEtlaContracts(etlaContracts);
  } else {
    const etlaContract = contractList.items.find((contract) => contract.isBuyingProgramETLA());
    addBannersForEtlaContracts(etlaContract ? [etlaContract] : []);
  }
}

// Display message if new VIP contract within the default window which is the last 7 days.
// Note that depending on the "newness" of the VIP contract this banner can be shown in addition
// to some of the ETLA banners above.
function notifyForNewVipContract() {
  const contractList = rootStore.organizationStore.contractList;
  const newVipContract = getNewVipContract(contractList);

  if (newVipContract) {
    const canViewContracts = canViewAccountOverviewPage();
    if (canViewContracts) {
      const banner = {
        isDismissible: true,
        linkText: 'globalBanner.linkText.viewDetails',
        linkUiSref: 'account.overview',
        message: 'globalBanner.messages.vip.newContract',
        type: GLOBAL_BANNER_TYPES.INFO,
      };
      rootStore.organizationStore.globalBannerStore.add(banner);

      dispatchUiEventAnalyticsLocal('ContractBanner:NewVip:VIP');
    }
  }
}

//////////

/**
 * @description Helper to get the translated message for a banner.
 * @param {Object} options - Top level wrapper object.
 * @property {Contract} options.contract - Contract whose end date and grace
 *     period end date will potentially be read.
 * @property {string} options.messageKey - String key for the banner string.
 * @returns {Object} - Relevant banner message key, the relevant dates and
 *     message arguments.
 */
function assembleMessage({contract, messageKey}) {
  const gracePeriodEndDateVal = contract.getComplianceSymptom(
    CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_EXPIRATION_UNTIL
  );
  const notificationPeriodEndDate = contract.getComplianceSymptom(
    CONTRACT_COMPLIANCE_SYMPTOMS.CAN_MESSAGE_UPCOMING_EXPIRATION_UNTIL
  );
  const messageArgs = feature.isEnabled('temp_pa2881_multiple_contracts')
    ? {
        displayName: contract.displayName,
        goUrlName: 'contract_expiration_prod',
        linkUiSref: '',
        linkUiSrefArgs: {
          action: ACCOUNT_STATE_PARAM.ACTION.OPEN_ACCOUNT_MANAGER_DIALOG,
          contractId: contract.id,
        },
      }
    : {goUrlName: 'contract_expiration_prod'};
  return {
    message: `globalBanner.messages.contract.compliance.${messageKey}`,
    messageArgs,
    messageDates: {
      endDate: contract.getEndDate(),
      gracePeriodEndDate: gracePeriodEndDateVal,
      notificationPeriodEndDate,
    },
  };
}

/**
 * @description Helper to take an impression string and invoke the analytics
 *     dispatch method.
 * @param {string} impression - Impression string that includes context
 *     information depending on which banner is triggering analytics.
 */
function dispatchUiEventAnalyticsLocal(impression) {
  dispatchUiEventAnalytics({
    eventAction: EVENT_ACTION.DISPLAY,
    eventName: EVENT_NAME.GLOBAL_BANNER,
    interaction: {
      impression,
    },
  });
}

/**
 * @description Helper to check if the expiry banner should be displayed based on
 * the override_expiration_banner value in contractReferences.
 *
 * @param {Contract} contract - Contract to check for the flag
 * @returns {boolean} - Returns true if override_expiration_banner is not false, otherwise false. Also defaults to true if the flag is not present.
 */
const isExpirationPhaseBannerEnabled = (contract) => {
  const contractPreferences = contract.contractReferences?.find(
    (ref) => ref.key === 'ADMINISTRATION_PREFERENCES'
  );

  if (contractPreferences) {
    try {
      const preferences = JSON.parse(contractPreferences.value);
      return preferences.override_expiration_banner !== 'false';
    } catch {
      log.error('Error parsing contract preferences for contract', contract.id);
      return true;
    }
  }

  return true;
};

/**
 * @description Determines if the expiration banner can be displayed. Combines checks
 * for both contract settings and expiration phase.
 *
 * @param {Contract} contract - The contract to check.
 * @param {ExpirationPhase} expirationPhase - The expiration phase to check.
 * @returns {boolean} - Returns true if both conditions allow the banner, otherwise false.
 */
const canDisplayExpirationBanner = (contract, expirationPhase) =>
  isExpirationPhaseBannerEnabled(contract) && shouldShowBannerForExpirationPhase(expirationPhase);

const getMessage = (isTrialETLA, stringKey) => {
  if (feature.isEnabled('temp_pa2881_multiple_contracts')) {
    if (isTrialETLA) {
      return `trial.${stringKey}`;
    }
    return `${stringKey}2`;
  }
  return stringKey;
};

/**
 * @description Helper to obtain the banner for a contract that might be in
 *     an expiration phase.
 *
 * @param {Contract} contract - Contract to generate a banner for.
 * @returns {Object} Banner with appropriate content.
 */
function getBannerForExpirationPhase(contract) {
  const expirationPhase = getExpirationPhaseFromComplianceSymptoms(contract);
  const isTrialETLA = trialHelper.isTrialETLAContract(contract);

  if (!canDisplayExpirationBanner(contract, expirationPhase)) {
    return undefined;
  }

  let message, type;
  switch (expirationPhase) {
    case CONTRACT_EXPIRATION_PHASE.NOTIFICATION:
      message = contract.isModelAllocation()
        ? 'allocation.notification'
        : getMessage(isTrialETLA, 'etla.notification');
      type = GLOBAL_BANNER_TYPES.WARNING;
      break;
    case CONTRACT_EXPIRATION_PHASE.GRACE:
      message = contract.isModelAllocation()
        ? 'allocation.grace'
        : getMessage(isTrialETLA, 'etla.grace');
      type = isTrialETLA ? GLOBAL_BANNER_TYPES.INFO : GLOBAL_BANNER_TYPES.ERROR;
      break;
    default:
      message = contract.isModelAllocation()
        ? 'allocation.postGrace'
        : getMessage(isTrialETLA, 'etla.postGrace');
      type = isTrialETLA ? GLOBAL_BANNER_TYPES.INFO : GLOBAL_BANNER_TYPES.ERROR;
      break;
  }

  triggerAnalyticsForExpirationPhase({contract, expirationPhase});

  return populateBannerForExpirationPhase({contract, messageKey: message, type});
}

/**
 * @description Helper to obtain the banner content for a contract in
 *     expiration phase.
 * @param {Object} options - Top level wrapper object.
 * @property {Contract} contract - Contract pass through so that the relevant
 *     date fields can be filled into the banner message.
 * @property {string} messageKey - String key for the message that should be
 *     populated in the banner.
 * @property {string} type - Type of banner being generated, either WARNING or
 *     ERROR.
 * @returns {Object} Banner content
 */
function populateBannerForExpirationPhase({contract, messageKey, type}) {
  return {
    ...assembleMessage({contract, messageKey}),
    isDismissible: false,
    type,
  };
}

/**
 * @description Helper to check if we should show a banner for a given
 *     expiration phase.
 * @param {string} expirationPhase - Expiration phase a contract might be in.
 * @returns {boolean} True if the expiration phase is one of NOTIFICATION,
 *     GRACE, or POST_GRACE, false otherwise.
 */
function shouldShowBannerForExpirationPhase(expirationPhase) {
  return [
    CONTRACT_EXPIRATION_PHASE.NOTIFICATION,
    CONTRACT_EXPIRATION_PHASE.GRACE,
    CONTRACT_EXPIRATION_PHASE.POST_GRACE,
  ].includes(expirationPhase);
}

/**
 * @description Helper to assemble the analytics impression and invoke the
 *    dispatch analytics util method.
 * @param {Object} options - Top level wrapper object.
 * @property {Contract} contract - Contract whose properties will dictate what
 *    impression string is assembled.
 * @property {string} expirationPhase - Expiration phase a contract might be in.
 */
function triggerAnalyticsForExpirationPhase({contract, expirationPhase}) {
  const contractTypeImpression = contract.isModelAllocation() ? 'Allocation' : 'ETLA';
  const expirationPhaseImpression = ExpirationPhaseToImpression[expirationPhase];
  const impression = `ContractBanner:${expirationPhaseImpression}:${contractTypeImpression}`;

  dispatchUiEventAnalyticsLocal(impression);
}

// eslint-disable-next-line import/prefer-default-export -- Utils file
export {notifyContractStateBanners};
