// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Image, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

/**
 * SophiaPromoImageDisplay component.
 *
 * This component displays an image based on the provided payload.
 * If the payload or imageSourceUrl is missing, it returns null.
 * The alt text for the image is either provided by the payload or defaults to a localized message.
 *
 * The image is displayed with the `objectFit="cover"` setting, which ensures that the image covers the entire area of
 * the container while maintaining its aspect ratio.
 *
 * @component
 * @returns {JSX.Element|null} The rendered component or null if no imageSourceUrl is provided.
 */
const SophiaPromoImageDisplay = () => {
  const intl = useIntl();

  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  if (!payload.imageSourceUrl) {
    return null;
  }

  const altTextDefault = intl.formatMessage({
    id: 'common.sophia.sophiaPromo.image.altText.default',
  });

  return (
    <View data-testid="one-console-promo-image-display" paddingBottom="size-100">
      {payload.imageSourceUrl && (
        <Image
          alt={payload.imageAltText || altTextDefault}
          data-testid="one-console-promo-image-display-img"
          height="auto"
          objectFit="cover"
          src={payload.imageSourceUrl}
        />
      )}
    </View>
  );
};

SophiaPromoImageDisplay.propTypes = {};

export default SophiaPromoImageDisplay;
