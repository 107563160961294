const ACCOUNT_OVERVIEW_ITEM = 'account-overview-item';
const CONTRACT_DETAILS_ITEM = 'contract-details-item';
const WAIT_UNTIL_ACCOUNT_PAGE_FINISHES_REFRESHING = 500;
const INTEGRATED_ADD_ON = 'INTEGRATED_ADD_ON';

export {
  ACCOUNT_OVERVIEW_ITEM,
  CONTRACT_DETAILS_ITEM,
  WAIT_UNTIL_ACCOUNT_PAGE_FINISHES_REFRESHING,
  INTEGRATED_ADD_ON,
};
