export const contractsBaseParams = [
  'ACCOUNT_INFO',
  'BILLING_INFO',
  'CHANGE_OWNER_INVITATION',
  'COMPARISON_PRICING',
  'COMPLIANCE_DATA',
  'COMPLIANCE_SYMPTOMS',
  'CONTRACT_REFERENCES',
  'CURRENCY_IMAGE_URL',
  'INACTIVE',
  'LOYALTY_INFO',
  'MARKET_SEGMENT',
  'MARKET_SUBSEGMENT',
  'MIGRATIONS',
  'ORG_NAME',
  'OWNER_INFO',
  'PREFERRED_LANGUAGE',
  'RENEWAL_INFO',
  'REQUESTED_PLAN_TYPE',
  'TAX_TERM',
  'TERMS_AND_CONDITIONS',
  'VIRTUAL_CONTRACTS',
];
