import {useCallback} from 'react';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';

import {
  PATH_SETTINGS_ASSET,
  PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS,
  PATH_SETTINGS_ASSET_CREDENTIALS,
  PATH_SETTINGS_ASSET_MIGRATION,
  PATH_SETTINGS_ASSET_SHARING_OPTIONS,
  PATH_SETTINGS_AUTHENTICATION,
  PATH_SETTINGS_CONSOLE,
  PATH_SETTINGS_CONTENT_LOGS,
  PATH_SETTINGS_ENCRYPTION,
  PATH_SETTINGS_IDENTITY,
  PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS,
  PATH_SETTINGS_IDENTITY_DIRECTORIES,
  PATH_SETTINGS_IDENTITY_DOMAINS,
  PATH_SETTINGS_IDENTITY_GUIDED_SETUP,
  PATH_SETTINGS_IDENTITY_TRUSTEES,
  PATH_SETTINGS_ROSTER_SYNC,
  PATH_SETTINGS_SECURITY_CONTACTS,
  PATH_SETTINGS_SHARED_PROJECT_POLICIES,
} from '../routing/settingsPaths';

/** Hook which returns methods with generated Settings pathnames */
const useSettingsPaths = () => {
  const assetPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_ASSET, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const assetSharingOptionsPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_ASSET_SHARING_OPTIONS, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const assetAuthorizedDomainsPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_ASSET_AUTHORIZED_DOMAINS, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const assetCredentialsPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_ASSET_CREDENTIALS, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const assetMigrationPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_ASSET_MIGRATION, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const authenticationPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_AUTHENTICATION, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const consoleSettingsPath = useCallback(
    () => generatePath(PATH_SETTINGS_CONSOLE, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const contentLogsPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_CONTENT_LOGS, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const encryptionPath = useCallback(
    () => generatePath(PATH_SETTINGS_ENCRYPTION, {orgId: rootStore.organizationStore.activeOrgId}),
    []
  );

  const identityPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_IDENTITY, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const identityAccessRequestsPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_IDENTITY_ACCESS_REQUESTS, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const identityDirectoriesPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_IDENTITY_DIRECTORIES, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const identityDomainsPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_IDENTITY_DOMAINS, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const identityGuidedSetupPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_IDENTITY_GUIDED_SETUP, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const identityTrusteesPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_IDENTITY_TRUSTEES, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const securityContactsPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_SECURITY_CONTACTS, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const sharedProjectPoliciesPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_SHARED_PROJECT_POLICIES, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  const rosterSyncPath = useCallback(
    () =>
      generatePath(PATH_SETTINGS_ROSTER_SYNC, {
        orgId: rootStore.organizationStore.activeOrgId,
      }),
    []
  );

  return {
    assetAuthorizedDomainsPath,
    assetCredentialsPath,
    assetMigrationPath,
    assetPath,
    assetSharingOptionsPath,
    authenticationPath,
    consoleSettingsPath,
    contentLogsPath,
    encryptionPath,
    identityAccessRequestsPath,
    identityDirectoriesPath,
    identityDomainsPath,
    identityGuidedSetupPath,
    identityPath,
    identityTrusteesPath,
    rosterSyncPath,
    securityContactsPath,
    sharedProjectPoliciesPath,
  };
};

export default useSettingsPaths;
