// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Badge, Flex, Text, View} from '@adobe/react-spectrum';
import React from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

/**
 * SophiaPromoRecommendationReason component.
 *
 * This component displays a recommendation reason based on the provided payload.
 * It uses localization to display a label and shows a badge with the reason text.
 *
 * Note the reasonVariant is used to determine the badge variant, and as such must be set to the values
 * documented here: https://react-spectrum.adobe.com/react-spectrum/Badge.html#variant
 *
 * @component
 * @returns {JSX.Element|null} The rendered component or null if no valid payload is provided.
 */
const SophiaPromoRecommendationReason = () => {
  const intl = useIntl();

  const {payload} = useJsonPayload();

  if (!payload) {
    return null;
  }

  if (!payload.reasonText || !payload.reasonVariant) {
    return null;
  }

  // Note: the current approach is to use uppercase in the localisation files, which this does, but this could be
  // implemented with CSS (for future consideration): https://developer.mozilla.org/en-US/docs/Web/CSS/text-transform
  const priceRecommendationLabel =
    intl.formatMessage({id: 'common.sophia.sophiaPromo.recommendation.label'}) ||
    'RECOMMENDED BECAUSE...';

  return (
    <View data-testid="one-console-promo-reason">
      <Flex direction={{base: 'column'}} gap={{base: 'size-50'}}>
        <Text data-testid="one-console-promo-reason-label" UNSAFE_style={{color: '#888888'}}>
          {priceRecommendationLabel}
        </Text>
        <Badge
          data-testid="one-console-promo-reason-badge"
          height="auto"
          variant={payload.reasonVariant}
        >
          <Text data-testid="one-console-promo-reason-text">{payload.reasonText}</Text>
        </Badge>
      </Flex>
    </View>
  );
};

SophiaPromoRecommendationReason.propTypes = {};

export default SophiaPromoRecommendationReason;
