import {throwLoaderNoAccessError} from 'core/router/loaders';
import {canViewRosterSync} from 'core/settings/access/settingsAccess';
import {getAllOrgRosterSyncs} from 'features/settings/hooks/api/useEduRosterSync';

/**
 * Loads the data for the roster sync tab
 */
const rosterSyncLoader = async ({request}) => {
  const hasAccess = await canViewRosterSync();
  if (!hasAccess) {
    throwLoaderNoAccessError(request);
  }

  const rosterSyncs = await getAllOrgRosterSyncs();

  return {
    rosterSyncs,
  };
};

export default rosterSyncLoader;
