/* eslint-disable max-lines -- ONESIE-44356 - Temp code to be removed/refactored once experiments are complete */

import {
  AuthenticatedUser,
  EVENT_ACTION,
  dispatchUiEventAnalytics,
  feature,
  navBus,
} from '@admin-tribe/acsc';
import {
  InfoItem,
  InfoItemScorecard,
  NavigationAnchor,
  OverlayWait,
  Page,
  PageActions,
  PageContent,
  PageDescription,
  PageHeader,
  PageInfoBar,
  PageNav,
} from '@admin-tribe/acsc-ui';
import {Button, DialogTrigger, Link} from '@adobe/react-spectrum';
import {REQUEST_ACCESS_STATUS} from '@pandora/data-model-acrs';
import {GoUrl} from '@pandora/react-go-url';
import {ProductRequestSettingsModal} from '@pandora/react-product-request-settings-modal';
import {
  RequestAccessTableSection,
  TABLE_ACTIONS,
} from '@pandora/react-request-access-table-section';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Outlet, generatePath} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import rootStore from 'core/RootStore';
import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';
import chatProvider from 'core/services/chat/chatProvider';
import useInsightsPaths from 'features/insights/hooks/useInsightsPaths';
import useFireflyOfferHandler from 'features/products/hooks/useFireflyOfferHandler';
import ProductApprovalSuccessMessage from 'features/products/pages/requests/ProductApprovalSuccessMessage';
import {goToProductsAutoAssignmentRules} from 'features/products/routing/navigation-callbacks/navigationCallbacks';
import {PATH_PRODUCTS_REQUEST_REVIEW} from 'features/products/routing/productsPaths';
import {getHrefForUserDetails} from 'features/users/routing/navigation-callbacks/navigationCallbacks';

import {ADD_PRODUCT_STEPS} from '../../components/add-product-modal-wrapper/AddProduct.constants';
import AddProductModalWrapper from '../../components/add-product-modal-wrapper/AddProductModalWrapper';
import useAccessRequestPolicies from '../../hooks/useAccessRequestPolicies';
import useUserAccessRequestLists from '../../hooks/useUserAccessRequestLists';
/** Request Products and Services Org Policy name */
const REQUEST_PRODUCT_SERVICES = 'authzRequests';

const REQUEST_ACCESS_ANALYTICS_PAGE_NAME = 'requestAccess';

/**
 * Defines the product requests page under the Products tab.
 */
// eslint-disable-next-line max-statements, complexity -- needs refactoring and post removal of temp_review_modal_signals_2
const ProductRequestsPage = (props) => {
  // NOTE See ONESIE-42752 for refactor of selection of best product
  let onTnoPrimaryCTA, onTnoSecondaryCTA, onTnoSelectBestProduct;
  if (feature.isEnabled('temp_tno_rejectproductassign_modal')) {
    ({tnoProps: {onTnoPrimaryCTA, onTnoSecondaryCTA, onTnoSelectBestProduct} = {}} = props);
  }

  const [showBuyMoreModal, setShowBuyMoreModal] = useState(false);
  const [buyMoreRequestIds, setBuyMoreRequestIds] = useState([]);
  const [addProductsTrackingData, setAddProductsTrackingData] = useState(undefined);
  const [buyMoreOfferId, setBuyMoreOfferId] = useState();

  const intl = useIntl();
  const adminId = AuthenticatedUser.get().getId();
  const {logDetails} = useInsightsPaths();

  useDocumentTitle({key: 'products.requests.title'});

  const accessRequestListContext = useUserAccessRequestLists();

  const {
    data: userAccessRequestLists,
    error,
    isLoading: accessRequestListsLoading,
    updateData: updateUserAccessRequestLists,
    unmatchedOfferIds,
  } = accessRequestListContext;

  const {
    policies: orgPolicies,
    isLoading: orgPoliciesLoading,
    refreshPolicies: refreshOrgPolicies,
  } = useAccessRequestPolicies();

  const getAnalyticsImpression = useCallback(
    (requestIds) => `contextRequestIds:${requestIds && requestIds.join('|')}`,
    []
  );

  const getUserDetailsLink = useCallback(
    (member) => (
      <Link>
        <NavigationAnchor href={getHrefForUserDetails({userId: member.id})}>
          {member.getDisplayName()}
        </NavigationAnchor>
      </Link>
    ),
    []
  );

  const onBuyMore = useCallback(
    (requestIds, productInfo) => {
      let foundOfferId;
      setBuyMoreRequestIds(requestIds);
      if (feature.isEnabled('temp_review_modal_signals_2')) {
        const offerId = rootStore.organizationStore.productList.items.find(
          (product) => product.id === productInfo?.licenseId
        )?.offerId;
        if (offerId) {
          foundOfferId = offerId;
          setBuyMoreOfferId(offerId);
        }
      }
      if (!foundOfferId && feature.isEnabled('temp_tno_streamline_m1_requestaccess')) {
        const offerId = unmatchedOfferIds?.find(
          (offer) => offer.appAuthRequestId === productInfo?.appAuthRequestId
        )?.offerId;
        if (offerId) {
          setBuyMoreOfferId(offerId);
        }
      }
      setAddProductsTrackingData(requestIds ? requestIds.join(',') : null);

      dispatchUiEventAnalytics({
        eventAction: EVENT_ACTION.CLICK,
        eventName: `${REQUEST_ACCESS_ANALYTICS_PAGE_NAME}:BuyMore`,
        interaction: {
          impression: getAnalyticsImpression(requestIds),
        },
      });

      setShowBuyMoreModal(true);
    },
    [getAnalyticsImpression, unmatchedOfferIds]
  );

  const onBuyMoreModalClose = useCallback(() => {
    setShowBuyMoreModal(false);
  }, []);

  // ONESIE-43019 / ONESIE-43618 - a change or misunderstanding of the data message format from Add Product Modal, support both
  const getAnalyticsProductListItems = (data) => {
    // original message format seen before ONESIE-43019
    if (data?.products) {
      return data?.products?.map((product) => ({
        licenseCount: product?.quantity,
        offer_id: product?.offerId,
      }));
    }
    // new message format seen after ONESIE-43019
    const cartItems = data?.actions?.[0]?.actionMessage?.data?.cartItems;
    if (cartItems) {
      return cartItems?.map((product) => ({
        licenseCount: product?.quantity,
        offer_id: product?.offerId,
      }));
    }

    // we couldn't derive from the message so return an empty array
    return [];
  };

  const onBuyMoreModalSuccess = useCallback(
    (data) => {
      updateUserAccessRequestLists();
      dispatchUiEventAnalytics({
        eventAction: EVENT_ACTION.SUCCESS,
        eventName: `${REQUEST_ACCESS_ANALYTICS_PAGE_NAME}:BuyMoreComplete`,
        interaction: {
          impression: getAnalyticsImpression(buyMoreRequestIds),
        },
        productList: {
          items: getAnalyticsProductListItems(data),
        },
      });
    },
    [buyMoreRequestIds, getAnalyticsImpression, updateUserAccessRequestLists]
  );

  encodeURI();
  const onReviewRequest = useCallback(
    (userId) =>
      navBus.navigate(
        generatePath(PATH_PRODUCTS_REQUEST_REVIEW, {
          orgId: rootStore.organizationStore.activeOrgId,
          userId,
        })
      ),
    []
  );

  // remove with temp_request_access_deep_link, logic moved to RequestModalWrapper since it directly routes to another page
  const onSetupAutomaticAssignment = useCallback(() => {
    goToProductsAutoAssignmentRules();
  }, []);

  const onClickAuditLogs = useCallback(() => {
    navBus.navigate(logDetails('auditLogsCal', 'view'));
  }, [logDetails]);

  const onTableSectionChange = useCallback(
    ({action}) => {
      if (Object.values(TABLE_ACTIONS).includes(action)) {
        updateUserAccessRequestLists();
      }
    },
    [updateUserAccessRequestLists]
  );

  const isPageLoading = orgPoliciesLoading;
  const isTableLoading = accessRequestListsLoading;
  const isProductRequestsAllowed = orgPolicies?.find(
    ({name}) => name === REQUEST_PRODUCT_SERVICES
  )?.value;
  const disabledFeatures = feature.isEnabled('bug_fix_e2e_26843')
    ? 'add_product_with_quick_assign'
    : undefined;
  const onDisplay = useFireflyOfferHandler();

  return (
    <>
      <Page data-testid="product-requests-page" isLoading={isPageLoading}>
        <PageHeader
          title={intl.formatMessage({
            id: 'products.requests.title',
          })}
        />
        <PageDescription>
          <FormattedMessage
            id="products.requests.description"
            values={{
              accessGoUrl: (linkText) => <GoUrl name="admin_product_access">{linkText}</GoUrl>,
              autoAssignGoUrl: (linkText) => <GoUrl name="admin_auto_assign">{linkText}</GoUrl>,
            }}
          />
        </PageDescription>
        <PageActions>
          <Button data-testid="view-audit-logs-button" onPress={onClickAuditLogs} variant="primary">
            <FormattedMessage id="products.requests.actionButton.viewAuditLogs" />
          </Button>
          <DialogTrigger>
            <Button data-testid="edit-settings-button" variant="primary">
              <FormattedMessage id="products.requests.actionButton.editSettings" />
            </Button>
            {(close) => (
              <ProductRequestSettingsModal
                onCancel={close}
                onSubmit={() => {
                  close();
                  refreshOrgPolicies();
                }}
                orgId={rootStore.organizationStore.activeOrgId}
              />
            )}
          </DialogTrigger>
        </PageActions>
        <PageNav />
        <PageInfoBar>
          <InfoItem
            key="product-requests"
            label={intl.formatMessage({id: 'products.requests.infoBar.productRequestsLabel'})}
            value={
              isProductRequestsAllowed
                ? intl.formatMessage({
                    id: 'products.requests.infoBar.productRequestsValueEnabled',
                  })
                : intl.formatMessage({
                    id: 'products.requests.infoBar.productRequestsValueDisabled',
                  })
            }
          />
          <InfoItemScorecard
            key="pending-requests"
            label={intl.formatMessage({id: 'products.requests.infoBar.pendingRequestsLabel'})}
            value={userAccessRequestLists?.reduce(
              (currentPendingRequests, accessRequestList) =>
                currentPendingRequests +
                accessRequestList.accessRequests.filter(
                  (request) => request.status === REQUEST_ACCESS_STATUS.PENDING
                ).length,
              0
            )}
          />
        </PageInfoBar>
        <PageContent>
          <OverlayWait isLoading={isTableLoading}>
            <RequestAccessTableSection
              adminAccountId={adminId}
              getUserDetailsLink={getUserDetailsLink}
              // remove isAllowedToAddProducts with temp_request_access_deep_link.
              // This is unnecessary as this is a pass through from table to modal
              isAllowedToAddProducts={
                feature.isEnabled('temp_request_access_deep_link')
                  ? undefined
                  : isAllowedToAddProducts()
              }
              isDisabled={isTableLoading}
              isServerError={!!error}
              items={userAccessRequestLists}
              // remove onBuyMore with temp_request_access_deep_link.
              // This is unnecessary since this is a modal callback, Outlet callback will trigger `onBuyMore`
              onBuyMore={feature.isEnabled('temp_request_access_deep_link') ? undefined : onBuyMore}
              onDisplay={feature.isEnabled('temp_firefly_change_plan') ? onDisplay : undefined}
              // When undefined, this param uses the internally launched modal in the RequestAccessTableSection component
              onReviewRequest={
                feature.isEnabled('temp_request_access_deep_link') ? onReviewRequest : undefined
              }
              // remove onSetupAutomaticAssignment with temp_request_access_deep_link.
              // This is unnecessary since this is a modal callback, RequestModalWrapper will respond to the callback to navigate
              onSetupAutomaticAssignment={
                feature.isEnabled('temp_request_access_deep_link')
                  ? undefined
                  : onSetupAutomaticAssignment
              }
              onTableSectionChange={onTableSectionChange}
              pageNumber={0}
            />
            {feature.isEnabled('temp_request_access_deep_link') &&
              (!onTnoPrimaryCTA || !onTnoSecondaryCTA) && (
                // using hook in the child route resulted in another network call, so just pass in the same results
                // since parent route will always be around for the child route
                <Outlet
                  context={{
                    accessRequestListContext,
                    onBuyMore,
                    ...(feature.isEnabled('temp_firefly_change_plan') ? {onDisplay} : undefined),
                    onTableSectionChange,
                  }}
                />
              )}
            {feature.isEnabled('temp_request_access_deep_link') &&
              onTnoPrimaryCTA &&
              onTnoSecondaryCTA && (
                // using hook in the child route resulted in another network call, so just pass in the same results
                // since parent route will always be around for the child route
                <Outlet
                  context={{
                    accessRequestListContext,
                    onBuyMore,
                    ...(feature.isEnabled('temp_firefly_change_plan') ? {onDisplay} : undefined),
                    onTableSectionChange,
                    onTnoPrimaryCTA,
                    onTnoSecondaryCTA,
                    onTnoSelectBestProduct,
                  }}
                />
              )}
          </OverlayWait>
          <ProductApprovalSuccessMessage />
        </PageContent>
      </Page>
      {showBuyMoreModal && (
        <AddProductModalWrapper
          chat={chatProvider}
          disabledFeatures={disabledFeatures}
          items={
            ((feature.isEnabled('temp_review_modal_signals_2') ||
              feature.isEnabled('temp_tno_streamline_m1_requestaccess')) &&
              buyMoreOfferId && [
                {
                  offerId: buyMoreOfferId,
                  quantity: 1,
                },
              ]) ||
            undefined
          }
          onClose={onBuyMoreModalClose}
          onSuccess={onBuyMoreModalSuccess}
          step={
            feature.isEnabled('temp_tno_streamline_m1_requestaccess') && buyMoreOfferId
              ? ADD_PRODUCT_STEPS.REVIEW_ORDER
              : undefined
          }
          tracking={addProductsTrackingData}
          wcid={
            (feature.isEnabled('temp_tno_streamline_m1_requestaccess') &&
              buyMoreOfferId &&
              'add_license') ||
            (feature.isEnabled('temp_tno_streamline_m1_requestaccess') && 'add_product') ||
            undefined
          }
        />
      )}
    </>
  );
};

ProductRequestsPage.propTypes = {
  /**
   * Provide callback capabilities for TnO promo modal showing if required.
   */
  tnoProps: PropTypes.shape({
    onTnoPrimaryCTA: PropTypes.func,
    onTnoSecondaryCTA: PropTypes.func,
    onTnoSelectBestProduct: PropTypes.func,
  }),
};
export default ProductRequestsPage;
/* eslint-enable max-lines -- ONESIE-44356 - Temp code to be removed/refactored once experiments are complete */
