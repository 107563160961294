import {feature, log} from '@admin-tribe/acsc';

import {groupBy, sortProducts} from 'core/products/utils/productUtils';
import FiProductGroupProductList from 'core/services/product/product-group/product-list/FiProductGroupProductList';
import FigProductGroupProductList from 'core/services/product/product-group/product-list/FigProductGroupProductList';

import {getOrgConsumableAndDelegatableProducts} from './orgConsumablesUtils';

/**
 * @class OrgConsumables
 * @description Model for an individual OrgConsumables Object. The
 *   OrgConsumables is a front-end model that allows us to group an
 *   organizations products and fulfillable item groupings in one place.
 */
class OrgConsumables {
  figProductGroupProductLists = [];
  fiProductGroupProductLists = [];
  orgConsumableAndDelegatableProducts;
  orgId;
  productList;
  productsWithoutGroups = [];

  /**
   * @description Constructs an OrgConsumables object for an organization to
   *   use. This model has an orgId and a list of fiProductGroupProductLists,
   *   each list representing an organization-level consumable. Each
   *   fiProductGroupProductList is an aggregation of 1 or more products from
   *   the productList. Mainly used by OrgStore to cache a shared
   *   instance to use globally
   * @param {Object} options - options to configure the instance
   * @param {Object} options.orgId - id of organization
   * @param {Object} options.productList - the productList of organization
   */
  constructor({orgId, productList}) {
    this.orgId = orgId;
    this.productList = productList;

    // Create the product group lists and the list of products with the product groups
    // removed.
    this.#getProductLists(this.productList);

    this.orgConsumableAndDelegatableProducts = getOrgConsumableAndDelegatableProducts(
      this.productList,
      this.fiProductGroupProductLists
    );
  }

  #getProductLists(productList) {
    const dxProducts = [];
    const orgConsumableProducts = [];

    // Partition the productList into DX / marketing cloud products,
    // org consumable products, and all others.
    productList.items.forEach((product) => {
      if (product.isMarketingCloudProduct()) {
        dxProducts.push(product);
      } else if (product.fulfillableItemList.hasOrgOnDemandConsumable()) {
        orgConsumableProducts.push(product);
      } else {
        this.productsWithoutGroups.push(product);
      }
    });

    if (feature.isEnabled('temp_switch_figid_to_ai_code')) {
      const isFallbackEnabled = feature.isEnabled('temp_fallback_to_figid');
      const keySelector = isFallbackEnabled
        ? (item) => item.administrableItemCode || item.figId
        : (item) => item.administrableItemCode;

      this.groupAndProcessDxProducts(dxProducts, keySelector, isFallbackEnabled);
    } else {
      const groupedByFigIdMap = groupBy(dxProducts, (item) => item.figId);
      groupedByFigIdMap.forEach((items, figId) => {
        if (items.length > 1) {
          this.figProductGroupProductLists.push(
            new FigProductGroupProductList({
              figId,
              items,
            })
          );
        } else {
          // This marketing cloud product didn't get added to a productGroup
          // so add it back to the list of other products.
          this.productsWithoutGroups.push(items[0]);
        }
      });
    }

    const groupedByFiCodeMap = groupBy(
      orgConsumableProducts,
      (item) => item.fulfillableItemList.getOrgOnDemandConsumableItem().code
    );
    groupedByFiCodeMap.forEach((items, fiCode) => {
      this.fiProductGroupProductLists.push(
        new FiProductGroupProductList({
          fiCode,
          items,
        })
      );
    });
  }

  /**
   * @description Method to find an fiProductGroupProductList by the FI code
   * @param {String} fiCode - the fulfillable item code
   * @returns {FiProductGroupProductList|undefined} returns the fi product group
   *   product list if found, else returns undefined
   */
  getByFiCode(fiCode) {
    return this.fiProductGroupProductLists.find((item) => item.id === fiCode);
  }

  /**
   * @description Method to find a figProductGroupProductList by the fig id
   * @param {String} figId - the fig id
   * @returns {FigProductGroupProductList|undefined} returns the fi product group
   *   product list if found, else returns undefined
   */
  getByFigId(figId) {
    return this.figProductGroupProductLists.find((item) => item.id === figId);
  }

  /**
   * @description Method to find a product group by id
   * @param {String} groupId - the productGroup id
   * @returns {ProductGroupProductList|undefined} returns the fi or fig product group
   *   product list if found, else returns undefined
   */
  getByGroupId(groupId) {
    return this.getByFiCode(groupId) || this.getByFigId(groupId);
  }

  /**
   * @description The individual org consumable products will be filtered out of
   *   'products'. In addition to the user's products, any aggregated org
   *   consumable productGroupProductList items in this model and any org
   *   delegatable products owned by the organization will be added to the list
   *   before it is sorted/returned
   * @param {Object} collator - the collator to use when sorting products,
   *   usually the result of a useCollator hook
   * @param {Object} intl - the translation service to use when constructing the
   *   display list, usually the result of a useIntl hook
   * @param {Array} products - array of product items to display. An item may be
   *   undefined, and if so, should be sorted to the end of the list rather than
   *   being removed
   * @returns {<Product|ProductGroupProductList>[]} sorted array of Products and
   *   FiProductGroupProductList
   */
  getProductDisplayList(collator, intl, products) {
    const onDemandProducts = products.filter(
      (product) =>
        !product?.fulfillableItemList.hasOrgOnDemandConsumable() &&
        (feature.isEnabled('temp_b2b_free_provisioning')
          ? !product?.isDisplayLocationSettingsOnly()
          : true)
    );

    const onDemandOrgConsumableAndDelegatableProducts = [
      ...onDemandProducts,
      ...this.orgConsumableAndDelegatableProducts,
    ];
    const sortedProducts = sortProducts({
      collator,
      intl,
      products: onDemandOrgConsumableAndDelegatableProducts,
    });

    return sortedProducts;
  }

  /**
   * @description Helper to obtain only the ProductGroupProductList entries.
   * @returns {Array<ProductGroupProductList>} ProductGroupProductList entries
   */
  getProductGroupsOnly() {
    return [...this.figProductGroupProductLists, ...this.fiProductGroupProductLists];
  }

  /**
   * @description The list of products and productGroups for display in
   *   either the Products pod on the Overview page or on the 'All products' page.
   * @returns {<Product|ProductGroupProductList>[]} unsorted array of Products and
   *   ProductGroupProductList
   */
  getProductsAndProductGroups() {
    return [
      ...this.productsWithoutGroups,
      ...this.figProductGroupProductLists,
      ...this.fiProductGroupProductLists,
    ];
  }

  /**
   * @description Groups and processes DX products.
   * @param {Array} dxProducts - The list of DX products to group.
   * @param {Function} keySelector - Function to select the grouping key.
   * @param {boolean} isFallbackEnabled - Whether fallback logic is enabled.
   */
  groupAndProcessDxProducts(dxProducts, keySelector, isFallbackEnabled) {
    const groupedMapOfDxProducts = groupBy(dxProducts, keySelector);
    groupedMapOfDxProducts.forEach((items, key) => {
      if (items.length > 1) {
        const groupedProducts = {
          items,
        };

        if (isFallbackEnabled) {
          // Use administrableItemCode as key if available, otherwise fallback to figId
          if (items[0].administrableItemCode) {
            groupedProducts.administrableItemCode = key;
          } else {
            groupedProducts.figId = key;
          }
        } else {
          // If fallback is not enabled, straightforward use of administrableItemCode as key
          groupedProducts.administrableItemCode = key;
        }

        this.figProductGroupProductLists.push(new FigProductGroupProductList(groupedProducts));
      } else {
        // This marketing cloud product didn't get added to a productGroup
        // so add it back to the list of other products.
        this.productsWithoutGroups.push(items[0]);
      }

      if (key === undefined) {
        log.error(`undefined key encountered for DxProducts licenses in org - ${this.orgId}`);
      }
    });
  }

  /**
   * @description Method to determine if this organization has any organization
   *   consumables
   * @returns {Boolean} returns true if this organization has any organization
   *   consumables
   */
  hasConsumables() {
    return this.fiProductGroupProductLists.length > 0;
  }

  /**
   * @description Method to determine if this organization has any organization
   *   consumables or organization delegatables. An org delegatable product can
   *   be used by everyone in the organization.
   * @returns {Boolean} returns true if this organization has any organization
   *   consumables or organization delegatables
   */
  hasOrgConsumablesOrOrgDelegatables() {
    return this.orgConsumableAndDelegatableProducts.length > 0;
  }

  /**
   * @description Method to return the current unique key for this list
   * @returns {String} key to uniquely identify this list
   */
  key() {
    return this.orgId;
  }
}

export default OrgConsumables;
