/* eslint-disable max-lines -- slightly over the 400 line max, review later */
import {dispatchUiEventAnalytics, feature, log} from '@admin-tribe/acsc';

import AutoAssignmentRulesModalDialog from 'common/components/sophia/sophia-modal/auto-assignment-rules-modal/AutoAssignmentRulesModalDialog';
import {getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';
import rootStore from 'core/RootStore';

const MODAL_DIALOG_MAP = {
  autoAssignmentRules: AutoAssignmentRulesModalDialog,
};

const mapModalDialogComponent = (dialogName) => MODAL_DIALOG_MAP[dialogName];
/**
 * Map ODIN content coming from Sophia to modal fields
 */
const mapContent = (sophiaContent) => {
  const nestedData = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item;
  return {
    analyticsId: nestedData.analyticsId,
    body: nestedData.body.plaintext,
    body2: nestedData.body2?.plaintext,
    bodyImageRatio: nestedData.bodyImageRatio,
    bodyLearnMoreUrl: nestedData.bodyLearnMoreUrl,
    buttons: nestedData.buttons || [],
    heading: nestedData.heading,
    imageUrl: nestedData.imageUrl,
    modalSize: nestedData.modalSize,
    slotId: nestedData.slotId,
    slotOptions: nestedData.slotOptions,
  };
};

/**
 * Map ODIN Helpful Topics content coming from Sophia to modal fields
 */
const mapHelpfulTopicsContent = (sophiaContent) => {
  const topics = [];
  const helpfulTopics = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item.helpfulTopics;
  helpfulTopics.forEach((topic) => {
    topics.push({
      ctaLabel: topic.ctaLabel,
      ctaUrl: topic.ctaUrl,
      learnMoreText: topic.learnMoreText,
      learnMoreUrl: topic.learnMoreUrl,
      parent: topic.parent,
      text: topic.text,
      title: topic.title,
    });
  });

  return topics;
};

/**
 * Map ODIN content of promo section and banner coming from Sophia to modal fields
 */
const mapPromoContentItem = (contentItem) => {
  if (!contentItem || Object.keys(contentItem).length === 0) {
    return null;
  }

  return {
    analyticsId: contentItem.analyticsId,
    backgroundColor: contentItem.backgroundColor,
    backgroundImage: contentItem.backgroundImage,
    badgeType: contentItem.badgeColor,
    description: contentItem.description?.plaintext,
    disclosure: contentItem.disclosure?.plaintext,
    discountedPrice: contentItem.discountedPrice,
    discountPriceColor: contentItem.discountPriceColor,
    dismissible: contentItem.dismissible,
    displayExcludingVAT: contentItem.displayExcludingVAT,
    displayPriceStartingAt: contentItem.displayPriceStartingAt,
    displayTerms: contentItem.displayTerms,
    hoverOverText: contentItem.hoverOverText?.plaintext,
    imageUrl: contentItem.imageUrl,
    priceFrequency: contentItem.priceFrequency,
    primaryCTAAction: contentItem.primaryUrl,
    primaryCTALabel: contentItem.primaryCTALabel,
    primaryCTAVariant: contentItem.primaryCTAVariant,
    regularPrice: contentItem.regularPrice,
    secondaryCTAAction: contentItem.secondaryUrl,
    secondaryCTALabel: contentItem.secondaryCTALabel,
    sectionTitle: contentItem.sectionTitle,
    strikethroughColor: contentItem.strikethroughColor,
    tag: contentItem.badgeText,
    termsAndConditions: contentItem.termsAndConditions?.plaintext,
    termsHeading: contentItem.termsHeading,
    termsLabel: contentItem.termsLabel,
    title: contentItem.heading,
  };
};

/**
 * Map ODIN content of promo section and banner coming from Sophia to modal fields
 */
const mapPromoContent = (sophiaContent) => {
  if (!sophiaContent || !sophiaContent.data) {
    return null;
  }

  const keys = Object.keys(sophiaContent.data);
  if (keys.length === 0) {
    return null;
  }

  const contentItem = sophiaContent.data[keys[0]]?.item;
  if (!contentItem) {
    return null;
  }

  return mapPromoContentItem(contentItem);
};

/**
 * Map ODIN content of Hva banner of overview page card coming from Sophia to modal fields
 */
const mapOverviewHvaBannerContent = (sophiaContent) => {
  const content = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item;
  /**
   * Find the product that is entitled to the org and also the product code.
   */
  const findProduct = (productCodesForCampaign, offerIds) => {
    if (!productCodesForCampaign || !offerIds) {
      return null;
    }

    const productList = rootStore.organizationStore.productList.items;
    // Here we are filtering the product code that is this org is entitled to and also the product code that is in the content
    // Find the product that is entitled to the org and also the product code that is from the given offers.
    const entitledProductsForCampaign = [];
    productCodesForCampaign.forEach((productCode) => {
      offerIds[productCode].forEach((offerId) => {
        entitledProductsForCampaign.push(
          productList.filter(
            (product) => product.code === productCode && offerId === product.offerId
          )
        );
      });
    });
    // Do not show the banner if the org is not entitled to any of the products.
    if (entitledProductsForCampaign.flat().length === 0) {
      return null;
    }

    const eligibleProducts = [];
    // If there is more than one product code, pick the first unassigned one.
    // Design decision has changed due to https://jira.corp.adobe.com/browse/ONESIE-44306
    entitledProductsForCampaign.flat().forEach((product) => {
      rootStore.organizationStore.productList.jitRuleDefaultProfileDefined?.forEach((rule) => {
        if (
          product.code === rule.productCode &&
          product.offerId === rule.offerId &&
          !rule.isAssigned
        ) {
          eligibleProducts.push(product);
        }
      });
    });
    return eligibleProducts[0];
  };

  const displayCampaign = () => {
    if (content.modalDialogName === 'autoAssignmentRules') {
      return (
        findProduct(content.modalDialog.productCode?.split(','), content.modalDialog.offerIds) &&
        feature.isEnabled('temp_tno_overview_hva_banner_modal')
      );
    }
    return true;
  };

  if (displayCampaign()) {
    return {
      analyticsId: content.analyticsId,
      ctaLabel: content.primaryCtaLabel,
      description: content.description?.plaintext,
      dismissOnCTA: true,
      dismissOnCTATemp: true,
      header: content.heading,
      icon: {
        src: content.imageUrl,
      },
      // eslint-disable-next-line no-underscore-dangle -- Sophia system returned
      id: content._path,
      isDismissible: content.isDismissible,
      learnMoreHref: content.learnMoreUrl,
      ...(content.modalDialog
        ? {
            modalConfig: {
              closeProp: 'onClose',
              closeWithoutDismissProp: 'onCloseWithoutDismiss',
              Component: mapModalDialogComponent(content.modalDialogName),
              includeModalContainer: true,
              props: {
                callBeforeOpeningModal: () =>
                  dispatchUiEventAnalytics({
                    eventAction: 'click',
                    eventName: 'autoassignment:jitrule:tryitnow',
                  }),
                content: content.modalDialog,
                product: findProduct(
                  content.modalDialog.productCode.split(','),
                  content.modalDialog.offerIds
                ),
              },
            },
          }
        : {onCTA: getCtaClickEventCallback(content.primaryActionUrl)}),
      sectionTitle: content.sectionTitle,
    };
  }

  return null;
};

const RecommendationType = {
  PromotionInfoCard: 'PromotionInfoCard',
  PromotionOfferCard: 'PromotionOfferCard',
  PromotionRecommendationCard: 'PromotionRecommendationCard',
  RecommendationFeedImageCard: 'RecommendationFeedImageCard',
  RecommendationFeedInfoCard: 'RecommendationFeedInfoCard',
  RecommendationFeedVideoCard: 'RecommendationFeedVideoCard',
};

/**
 * Validates Sophia content to a recommendation section based on content type
 * @param {Object} contentItem - The Sophia content object containing recommendation data for a single item
 * @returns {Object} Mapped recommendation section with appropriate fields for the content type, or null if the item is missing required fields
 *
 * @example
 * const result = mapRecommendationSection(sophiaData, recommendationType.PromotionOfferCard, true);
 * Returns object with product offer fields including pricing, badges, and CTAs
 */
/* eslint-disable complexity -- this is clearest way to express it, the logic is not complex */
const validatePromoRecommendationContentItem = (contentItem) => {
  if (!contentItem) {
    return null;
  }

  // These represent the core requirements for each content type
  const hasCommon = !!(contentItem.description && contentItem.heading);
  const hasPrimaryAction = !!(contentItem.primaryUrl && contentItem.primaryCTALabel);
  const hasSecondaryAction = !!(contentItem.secondaryUrl && contentItem.secondaryCTALabel);
  const hasAction = hasPrimaryAction || hasSecondaryAction;
  const hasImage = !!contentItem?.image?.sourceUrl;
  const hasVideo = !!contentItem.video?.sourceUrl;
  const hasDiscountPrice = !!contentItem.discountedPrice;
  const hasRegularPrice = !!contentItem.regularPrice;
  const hasPrice = hasDiscountPrice && hasRegularPrice;

  const validationRules = {
    [RecommendationType.PromotionInfoCard]: {
      errorMessage:
        'PromotionInfoCard content is missing required fields, expected common, action, and no price',
      isValid: hasCommon && hasAction && !hasPrice,
    },
    [RecommendationType.PromotionOfferCard]: {
      errorMessage:
        'PromotionOfferCard content is missing required fields, expected common, action, and discount price',
      isValid: hasCommon && hasAction && hasDiscountPrice,
    },
    [RecommendationType.PromotionRecommendationCard]: {
      errorMessage:
        'PromotionOfferCard content is missing required fields, expected common, action, and discount price',
      isValid: hasCommon && hasAction && hasRegularPrice,
    },
    [RecommendationType.RecommendationFeedInfoCard]: {
      errorMessage:
        'RecommendationFeedInfoCard content is missing required fields, expected common and action',
      isValid: hasCommon && hasAction,
    },
    [RecommendationType.RecommendationFeedImageCard]: {
      errorMessage:
        'RecommendationFeedImageCard content is missing required fields, expected common, action, and image',
      isValid: hasCommon && hasAction && hasImage,
    },
    [RecommendationType.RecommendationFeedVideoCard]: {
      errorMessage:
        'RecommendationFeedVideoCard content is missing required fields, expected common, action, and video',
      isValid: hasCommon && hasAction && hasVideo,
    },
  };

  let isValid = false;
  const rule = validationRules[contentItem.recommendationType];
  if (rule) {
    isValid = rule.isValid;
    if (!isValid) {
      log.warn(`${rule.errorMessage}, received ${JSON.stringify(contentItem)}`);
    }
  } else {
    log.warn(`RecommendationFeed content is missing type, received ${JSON.stringify(contentItem)}`);
  }

  return isValid ? contentItem : null;
};
/* eslint-enable complexity -- this is the clearest way to express it, the logic is not complex */

/**
 * Maps Sophia content to a recommendation section based on content type
 * @param {Object} contentItem - The Sophia content object containing recommendation data for a single item
 * @returns {Object} Mapped recommendation section with appropriate fields for the content type
 *
 * @example
 * const result = mapPromoRecommendationContentItem(sophiaData);
 * Returns object with product offer fields including pricing, badges, and CTAs
 */
const mapPromoRecommendationContentItem = (contentItem) => {
  if (!contentItem || Object.keys(contentItem).length === 0) {
    return null;
  }

  const content = validatePromoRecommendationContentItem(contentItem);
  if (!content) {
    return null;
  }

  const common = {
    analyticsId: content.analyticsId,
    backgroundColor: content.backgroundColor,
    backgroundImage: content.backgroundImage,
    description: content.description?.plaintext,
    primaryCTAAction: content.primaryUrl,
    primaryCTALabel: content.primaryCTALabel,
    primaryCTAVariant: content.primaryCTAVariant,
    recommendationType: content.recommendationType,
    secondaryCTAAction: content.secondaryUrl,
    secondaryCTALabel: content.secondaryCTALabel,
    title: content.heading,
  };
  const variants = {
    [RecommendationType.RecommendationFeedInfoCard]: {
      // the recommendation style card with just text and CTAs
      reasonText: content?.reason?.text?.plaintext,
      reasonVariant: content?.reason?.variant,
    },
    [RecommendationType.RecommendationFeedImageCard]: {
      // the recommendation style card with an image at the top of the card
      imageAltText: content?.image?.altText,
      imageSourceUrl: content?.image?.sourceUrl,
      reasonText: content?.reason?.text?.plaintext,
      reasonVariant: content?.reason?.variant,
    },
    [RecommendationType.RecommendationFeedVideoCard]: {
      // the recommendation style card with a video at the top of the card
      reasonText: content?.reason?.text?.plaintext,
      reasonVariant: content?.reason?.variant,
      videoMimeType: content?.video?.mimeType,
      videoPosterUrl: content?.video?.posterUrl,
      videoSourceUrl: content?.video?.sourceUrl,
    },
    [RecommendationType.PromotionInfoCard]: {
      // a 'right rail' style promo panel, using no pricing or terms (not needed if no pricing)
      badgeIcon: content.badgeIcon,
      badgeType: content.badgeColor,
      imageUrl: content.imageUrl,
      tag: content.badgeText,
    },
    [RecommendationType.PromotionOfferCard]: {
      // a 'right rail' style promo panel, using discount pricing
      badgeIcon: content.badgeIcon,
      badgeType: content.badgeColor,
      disclosure: content.disclosure?.plaintext,
      discountedPrice: content.discountedPrice,
      discountPriceColor: content.discountPriceColor,
      displayExcludingVAT: content.displayExcludingVAT,
      displayPriceStartingAt: content.displayPriceStartingAt,
      displayTerms: content.displayTerms,
      hoverOverText: content.hoverOverText?.plaintext,
      imageUrl: content.imageUrl,
      priceFrequency: content.priceFrequency,
      regularPrice: content.regularPrice,
      strikethroughColor: content.strikethroughColor,
      tag: content.badgeText,
      termsAndConditions: content.termsAndConditions?.plaintext,
      termsHeading: content.termsHeading,
      termsLabel: content.termsLabel,
    },
    [RecommendationType.PromotionRecommendationCard]: {
      // a 'right rail' style promo panel, using regular pricing only
      badgeIcon: content.badgeIcon,
      badgeType: content.badgeColor,
      disclosure: content.disclosure?.plaintext,
      displayExcludingVAT: content.displayExcludingVAT,
      displayPriceStartingAt: content.displayPriceStartingAt,
      displayTerms: content.displayTerms,
      hoverOverText: content.hoverOverText?.plaintext,
      imageUrl: content.imageUrl,
      priceFrequency: content.priceFrequency,
      regularPrice: content.regularPrice,
      tag: content.badgeText,
      termsAndConditions: content.termsAndConditions?.plaintext,
      termsHeading: content.termsHeading,
      termsLabel: content.termsLabel,
    },
  };

  return {
    ...common,
    ...variants[content.recommendationType],
  };
};

/**
 * Maps Sophia content to a recommendation section based on content type
 * @param {Object} sophiaContent - The Sophia content object containing recommendation data
 * @returns {Object} Mapped recommendation section with appropriate fields for the content type
 * Map ODIN content of promo recommendation section from Sophia to modal fields
 */
const mapPromoRecommendationContent = (sophiaContent) => {
  if (!sophiaContent || !sophiaContent.data) {
    return null;
  }

  const keys = Object.keys(sophiaContent.data);
  if (keys.length === 0) {
    return null;
  }

  const contentItem = sophiaContent.data[keys[0]]?.item;
  if (!contentItem) {
    return null;
  }

  return mapPromoRecommendationContentItem(contentItem);
};

export {
  RecommendationType,
  mapContent,
  mapHelpfulTopicsContent,
  mapPromoContent,
  mapPromoContentItem,
  mapPromoRecommendationContent,
  mapPromoRecommendationContentItem,
  mapOverviewHvaBannerContent,
  validatePromoRecommendationContentItem,
};
/* eslint-enable max-lines -- slightly over the 400 line max, review later */
