import {
  AuthenticatedUser,
  CONTRACT_STATUS,
  Locale,
  configStore,
  eventBus,
  feature,
  log,
} from '@admin-tribe/acsc';
import {OverlayWait, showSuccess as showSuccessToast} from '@admin-tribe/acsc-ui';
import {Provider, lightTheme} from '@adobe/react-spectrum';
import {MessageType, SystemMessageSubType} from '@pandora/mini-app-messaging-types';
import {useIframeMessage} from '@pandora/react-app-context-iframe-message-handler';
import {MatDialog, MatDialogContainer} from '@pandora/react-mat-dialog';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {CART_EVENT} from 'features/offers/freeOfferCartConstants';

import useProductListForCancellation from '../../account/self-cancel/hooks/use-product-list-for-cancellation/useProductListForCancellation';
import {STATUS_CODE} from '../MiniAppConstants';

// eslint-disable-next-line no-unused-vars -- Need for styles in iframe
import styles from './CancelPlanModalWrapper.pcss';

const CANCEL_PLAN_MINI_APP_NAME = 'CancelPlanMiniApp';
const CANCEL_PLAN_MINI_APP_URL_ENV_PARAM = 'services.cancelPlanMiniApp';
const CANCEL_PLAN_WORKFLOW = 'cancel_plan';

/**
 * If the client passes the contractId, use that.
 * If not, find the contractId for the org if it has only one contract and that too is active.
 *
 * @param {String} contractId - Deeplink with contract id
 *
 * @returns contractId if found otherwise undefined
 */
const getActiveContractForMiniApp = (contractId) => {
  const {contractList} = rootStore.organizationStore;

  if (contractId) {
    return contractList.items.find((contract) => contract.id === contractId);
  }

  if (contractList.items.length === 1 && contractList.items[0].status === CONTRACT_STATUS.ACTIVE) {
    return contractList.items[0];
  }

  return undefined;
};

/**
 * Based on the FF, this function returns the respective config obj.
 *
 * @returns config from configStore
 */
const getMiniAppConfiguration = () => configStore.get(CANCEL_PLAN_MINI_APP_URL_ENV_PARAM);

/**
 * Setup Cancel Plan Mini App URL
 * See Parameters - https://git.corp.adobe.com/PandoraUI/commerce-mini-apps/blob/master/packages/react-mini-app-cancel-plan/README.md
 *
 * @param {String} contractId - Deeplink with contract id
 * @param {String} productId - Deeplink with product id
 *
 * @returns Cancel Plan Mini App URL
 */
const setUpMiniAppUrl = ({contractId, orgId, productId}) => {
  // Prepare for the Locale information, AEM takes locale in BCP-47 format
  const localeLanguage = Locale.get().activeLanguageBCP47Code;

  const {url} = getMiniAppConfiguration();

  // App Context Identifier as Mini App is loaded in iframe
  const iFrameCtx = 'if';

  // Basic parameters
  const params = {
    cli: 'ONESIE1',
    ctrId: contractId,
    ctx: iFrameCtx,
    curPid: productId,
    lang: localeLanguage,
    orgId,
    userId: AuthenticatedUser.get().getId(),
  };

  if (
    feature.isEnabled('temp_show_change_plan') &&
    feature.isDisabled('temp_redirect_change_plan_to_jarvis')
  ) {
    params.af = 'temp_show_change_plan';
  }

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);

    // Forward client ID to add product mini app
    if (urlParams.get('cli')) {
      params.cli = urlParams.get('cli');
    }

    if (urlParams.get('smkRgn')) {
      params.smkRgn = urlParams.get('smkRgn');
    }
  }

  return `${url}?${qs.stringify(params, {skipNulls: true})}`;
};

/**
 * @description Hook to manage the state of cancel plan modal
 */
const useCancelPlanModalContext = ({onClose}) => {
  // Show or close the modal dialog based on status of cancel plan workflow
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(true);

  const [appReady, setAppReady] = useState(false);

  // Show success message when the cancel is successful
  const intl = useIntl();
  const showSuccessMessage = useCallback(
    (state) => {
      const {productName, qty} = state;

      const message = intl.formatMessage(
        {id: 'products.cancel.toast.message.success'},
        {
          count: feature.isEnabled('temp_quantity_is_object') ? qty?.sel : qty,
          product: productName,
        }
      );

      showSuccessToast(message);
    },
    [intl]
  );

  // Get the reported state from the actions
  const getReportedState = ({actions}) =>
    actions
      .filter((action) => action.required)
      .map((action) => action.actionMessage)
      .filter((message) => message.subType === SystemMessageSubType.REPORT_STATE)
      .map((message) => message.data)
      .reduce((acc, _state) => ({...acc, ..._state}), {});

  // Message handler to process just the System events from cancel plan Mini App
  const systemMessageHandler = useCallback(
    // eslint-disable-next-line complexity -- need to handle multiple cases
    ({data, subType}) => {
      const {actions = [], statusCode} = data || {};
      const actionData = getReportedState({actions});

      // eslint-disable-next-line default-case -- no need to handle default case
      switch (subType) {
        case SystemMessageSubType.APP_LOADED:
          setAppReady(true);
          break;
        case SystemMessageSubType.APP_CLOSED:
          setShowCancelPlanModal(false);
          onClose?.();

          // Show success message
          if (
            statusCode === STATUS_CODE.FULLY_COMPLETE &&
            actionData?.wf === CANCEL_PLAN_WORKFLOW &&
            !actionData?.promoAccepted
          ) {
            showSuccessMessage(actionData);
          }

          // fetch the latest product list after successful cancel plan
          if (
            (statusCode === STATUS_CODE.FULLY_COMPLETE ||
              statusCode === STATUS_CODE.CANCEL_WITHOUT_PROGRESS) &&
            !!actionData?.oid &&
            (actionData?.wf !== CANCEL_PLAN_WORKFLOW || !!actionData?.promoAccepted)
          ) {
            // Firing event to trigger AcquisitionSummaryList refresh when temp_refresh_acquisition_summary flag is enabled
            if (feature.isEnabled('temp_refresh_acquisition_summary')) {
              eventBus.emit(CART_EVENT.SUBMIT, this);
            }
          }
          break;
      }
    },
    [onClose, showSuccessMessage]
  );

  return {appReady, showCancelPlanModal, systemMessageHandler};
};

/**
 * @description Hook to handle the messages from cancel plan mini app
 */
const useCancelPlanMessageHandler = ({systemMessageHandler}) => {
  // Message handler to process the events from cancel plan Mini App
  const messageHandler = useCallback(
    ({app, type, subType, data}) => {
      // Ignore the message if it is not from cancel plan Mini App
      if (app !== CANCEL_PLAN_MINI_APP_NAME) return;

      log.info('Message from Cancel Plan Mini App:', {data, subType, type});

      if (type === MessageType.SYSTEM) {
        systemMessageHandler({data, subType});
      } else if (type === MessageType.NAVIGATION) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- opens link
        window.open(data.externalUrl, data.target);
      }
    },
    [systemMessageHandler]
  );

  // Consume message from cancel plan Mini App iframe
  useIframeMessage(messageHandler);
};

/**
 * @description Hook to get the product list for cancellation
 */
const useGetProductListForCancellation = ({orgId}) => {
  const [initialized, setInitialized] = useState(false);
  const [products, setProducts] = useState([]);

  const {productList} = useProductListForCancellation({
    orgId,
  });

  // Initialize the products list
  useEffect(() => {
    if (initialized || !productList) return;

    setInitialized(true);
    setProducts(productList);
  }, [initialized, productList]);

  return {initialized, products};
};

/**
 * @description Represents a wrapper for Cancel Plan Mini App
 *
 * @param {String} contractId - Optional deeplink with contract id
 * @param {Function} onClose - Callback to run when the modal is closed
 * @param {String} productId - Deeplink with product id
 */
const CancelPlanModalWrapper = ({contractId, onClose, productId}) => {
  const intl = useIntl();

  const {organizationStore} = rootStore;
  const contract = getActiveContractForMiniApp(contractId);

  const activeContractId = contract?.id;
  const activeOrgId = contract?.orgId ?? organizationStore.activeOrgId;

  const {initialized} = useGetProductListForCancellation({orgId: activeOrgId});

  const miniAppUrl = useMemo(
    () => setUpMiniAppUrl({contractId: activeContractId, orgId: activeOrgId, productId}),
    [activeContractId, activeOrgId, productId]
  );

  const {appReady, showCancelPlanModal, systemMessageHandler} = useCancelPlanModalContext({
    onClose,
  });
  useCancelPlanMessageHandler({systemMessageHandler});

  if (!initialized || !showCancelPlanModal) return null;

  return (
    <Provider colorScheme="light" theme={lightTheme}>
      <MatDialogContainer
        isDismissable={false}
        isKeyboardDismissDisabled
        variant={{base: 'takeover', M: 'overlay'}}
      >
        <MatDialog aria-label={intl.formatMessage({id: 'account.cancelPlan.dialog.title'})}>
          <div styleName="dialog-content">
            <OverlayWait isLoading={!appReady} showContent size="M">
              <iframe
                src={miniAppUrl}
                styleName="cancel-plan-iframe"
                title={intl.formatMessage({id: 'account.cancelPlan.frame.title'})}
              />
            </OverlayWait>
          </div>
        </MatDialog>
      </MatDialogContainer>
    </Provider>
  );
};

CancelPlanModalWrapper.propTypes = {
  /**
   * Optional id which determines the active contract
   */
  contractId: PropTypes.string,
  /**
   * Handler that is called when the cancel-plan modal is closed.
   */
  onClose: PropTypes.func,
  /**
   * Id which determines the product to cancel
   */
  productId: PropTypes.string.isRequired,
};

export default CancelPlanModalWrapper;
export {CANCEL_PLAN_WORKFLOW};
