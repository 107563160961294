import {AuthenticatedUser, feature, navBus} from '@admin-tribe/acsc';
import {OverlayWait} from '@admin-tribe/acsc-ui';
import {AlertDialog, DialogContainer, View} from '@adobe/react-spectrum';
import {TABLE_ACTIONS} from '@pandora/react-request-access-table-section';
import {
  ReviewProductRequestModal,
  ReviewProductRequestModalTnoWrapper,
} from '@pandora/react-review-product-request-modal';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {generatePath, useOutletContext, useParams} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {isAllowedToAddProducts} from 'core/organizations/access/organizationAccess';
import {
  PATH_PRODUCTS_AUTO_ASSIGN,
  PATH_PRODUCTS_REQUESTS,
} from 'features/products/routing/productsPaths';

/**
 * @description send a custom event to Gainsight
 *
 * @param {boolean} approved Whether the requests were approved
 * @param {Array} requests The requests that were approved or denied
 *
 * @see
 * https://support.gainsight.com/PX/Administration/General/Custom_Events_and_Global_Context
 */
const sendGainsightEvent = (approved, requests) => {
  if (feature.isDisabled('temp_review_modal_signals')) {
    return;
  }

  const applicationIds = requests.flatMap((r) => r.applicationIds);
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- guarded but picked as error
  window?.aptrinsic('track', 'requests reviewed', {
    applicationIds,
    approved,
  });
};

const RequestModalWrapper = () => {
  const adminId = AuthenticatedUser.get().getId();

  const {
    accessRequestListContext,
    onBuyMore,
    onDisplay,
    onTableSectionChange,
    onTnoPrimaryCTA,
    onTnoSecondaryCTA,
    onTnoSelectBestProduct,
  } = useOutletContext();

  const {userId} = useParams();
  const intl = useIntl();
  const {activeOrgId: orgId, productList: activeOrgProducts} = rootStore.organizationStore;

  const {data: userAccessRequestLists, isLoading: accessRequestListsLoading} =
    accessRequestListContext;

  const userRequest = useMemo(
    () => userAccessRequestLists.find((user) => user.id === userId),
    [userAccessRequestLists, userId]
  );

  const onCancel = useCallback(() => {
    navBus.navigate(
      generatePath(PATH_PRODUCTS_REQUESTS, {
        orgId,
      })
    );
  }, [orgId]);

  const onSetupAutomaticAssignment = useCallback(() => {
    navBus.navigate(
      generatePath(PATH_PRODUCTS_AUTO_ASSIGN, {
        orgId,
      })
    );
  }, [orgId]);

  const onRefreshAccessRequestList = useCallback(() => {
    onTableSectionChange({action: TABLE_ACTIONS.REFRESH_ITEMS});
    onCancel();
  }, [onCancel, onTableSectionChange]);

  // ONESIE-39548 temp_tno_rejectproductassign_modal Behaviour - Promos at User Review
  if (feature.isEnabled('temp_tno_rejectproductassign_modal')) {
    return (
      <DialogContainer onDismiss={onCancel}>
        <View maxHeight="80vh" overflow="auto">
          <OverlayWait isLoading={accessRequestListsLoading}>
            {userRequest ? (
              <ReviewProductRequestModalTnoWrapper
                activeOrgProducts={activeOrgProducts?.items || []}
                adminAccountId={adminId}
                isAllowedToAddProducts={isAllowedToAddProducts()}
                onApprove={(requests) => sendGainsightEvent(true, requests)}
                onBuyMore={onBuyMore}
                onCancel={onCancel}
                onDeny={(requests) => sendGainsightEvent(false, requests)}
                onDisplay={onDisplay}
                onRefreshAccessRequestList={onRefreshAccessRequestList}
                onSetupAutomaticAssignment={onSetupAutomaticAssignment}
                onTnoPrimaryCTA={onTnoPrimaryCTA}
                onTnoSecondaryCTA={onTnoSecondaryCTA}
                onTnoSelectBestProduct={onTnoSelectBestProduct}
                userAccessRequestList={userRequest}
              />
            ) : (
              <AlertDialog
                onPrimaryAction={onCancel}
                primaryActionLabel={intl.formatMessage({id: 'common.buttons.ok'})}
                title={intl.formatMessage({id: 'products.requests.alert.title'})}
              >
                {intl.formatMessage({id: 'products.requests.alert.requestNotFound'})}
              </AlertDialog>
            )}
          </OverlayWait>
        </View>
      </DialogContainer>
    );
  }
  // Pre ONESIE-39548 temp_tno_rejectproductassign_modal Behaviour - Promos at User Review
  return (
    <DialogContainer onDismiss={onCancel}>
      <View maxHeight="80vh" overflow="auto">
        <OverlayWait isLoading={accessRequestListsLoading}>
          {userRequest ? (
            <ReviewProductRequestModal
              adminAccountId={adminId}
              isAllowedToAddProducts={isAllowedToAddProducts()}
              onApprove={(requests) => sendGainsightEvent(true, requests)}
              onBuyMore={onBuyMore}
              onCancel={onCancel}
              onDeny={(requests) => sendGainsightEvent(false, requests)}
              onDisplay={onDisplay}
              onRefreshAccessRequestList={onRefreshAccessRequestList}
              onSetupAutomaticAssignment={onSetupAutomaticAssignment}
              userAccessRequestList={userRequest}
            />
          ) : (
            <AlertDialog
              onPrimaryAction={onCancel}
              primaryActionLabel={intl.formatMessage({id: 'common.buttons.ok'})}
              title={intl.formatMessage({id: 'products.requests.alert.title'})}
            >
              {intl.formatMessage({id: 'products.requests.alert.requestNotFound'})}
            </AlertDialog>
          )}
        </OverlayWait>
      </View>
    </DialogContainer>
  );
};

RequestModalWrapper.propTypes = {};
export default RequestModalWrapper;
