const PATH_ACCOUNT_ROOT = '/:orgId/account';

const PATH_ACCOUNT_ADD_PRODUCTS = `${PATH_ACCOUNT_ROOT}/add-products`;
const PATH_ACCOUNT_BILLING_HISTORY = `${PATH_ACCOUNT_ROOT}/billing-history`;
const PATH_ACCOUNT_CANCEL_LICENSES = `${PATH_ACCOUNT_ROOT}/cancel-licenses`;
const PATH_ACCOUNT_CONTRACT_DETAILS = `${PATH_ACCOUNT_ROOT}/contract/:contractId`;
const PATH_ACCOUNT_ORGANIZATION_DETAILS = `${PATH_ACCOUNT_ROOT}/organization-details`;
const PATH_ACCOUNT_OVERVIEW = `${PATH_ACCOUNT_ROOT}/overview`;

const PATH_ACCOUNT_CHANGE_PLAN = `${PATH_ACCOUNT_ROOT}/change-plan`;
// productIdentifier can be productId or productArrangementCode
const PATH_ACCOUNT_CHANGE_SPECIFIC_PLAN = `${PATH_ACCOUNT_ROOT}/change-plan/:productIdentifier`;

export {
  PATH_ACCOUNT_ADD_PRODUCTS,
  PATH_ACCOUNT_BILLING_HISTORY,
  PATH_ACCOUNT_CANCEL_LICENSES,
  PATH_ACCOUNT_CHANGE_PLAN,
  PATH_ACCOUNT_CHANGE_SPECIFIC_PLAN,
  PATH_ACCOUNT_CONTRACT_DETAILS,
  PATH_ACCOUNT_ORGANIZATION_DETAILS,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_ROOT,
};
