import invokeMap from 'lodash/invokeMap';
import sum from 'lodash/sum';

import rootStore from 'core/RootStore';

// To fix https://jira.corp.adobe.com/browse/ONESIE-44184, we need to track the FireFly offers below.
// This is a temporary solution until stakeholders have a better solution.
const FIREFLY_STANDARD_OFFERS = [
  '689AD38853C566E1221BB6F6F5D6AAA3',
  '6932E47F56525C29FA897D155AA7B35C',
  'A46EDAFBA658D73FC5E0B3A3929B717A',
  '6C805B5F5F0E2AC02C26BC7465303187',
];

const FIREFLY_PRO_OFFERS = [
  '2CF27FC91B8B4833CBC8CF0EFFAD65E8',
  '5AAE83E719486756B8C4E3A5E9FE4567',
  'AC64F740D4A608ECE517488242D671BB',
  '9A6EE6089779FC4FA38AF1418651EF99',
];

/**
 * @description send a custom event to Gainsight
 *
 * @see
 * https://support.gainsight.com/PX/Administration/General/Custom_Events_and_Global_Context
 * @param changePlan - whether the event is for a change-plan or change-product
 * @param memberId - the memberId of the user from the access request
 */
const triggerGainsightCoachmark = ({changePlan, memberId}) => {
  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- send a Gainsight event
  window?.aptrinsic('track', 'requests reviewed', {changePlan});

  // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- send a Gainsight event
  window?.aptrinsic('set', 'user', {memberId});
};

const availableLicenses = (products) => {
  // eslint-disable-next-line lodash/chaining -- cannot chain
  const assignableLicenseCount = sum(invokeMap(products, 'getAssignableLicenseCount'));
  const activeCount = sum(products.map((p) => p.provisionedQuantity));
  return assignableLicenseCount - activeCount;
};
/**
 * @description Hook to handle Firefly offers and trigger appropriate Gainsight events.
 *
 * This callback will be passed through Pandora components review-product-requests-table-row that handle access requests to call this hook.
 * This callback receives an AccessRequest object and a boolean indicating whether the user is allowed to add products.
 */
const useFireflyOfferHandler = () => (accessRequest) => {
  const productList = rootStore.organizationStore.productList.items;

  // Collect all eligible $9.99 and $29.99 offers
  const {eligibleProOffers, eligibleStandardOffers} = productList.reduce(
    (acc, product) => {
      if (FIREFLY_PRO_OFFERS.includes(product.offerId)) {
        acc.eligibleProOffers.push(product);
      }
      if (FIREFLY_STANDARD_OFFERS.includes(product.offerId)) {
        acc.eligibleStandardOffers.push(product);
      }
      return acc;
    },
    {eligibleProOffers: [], eligibleStandardOffers: []}
  );

  // Only need to check if an org has purchased both STANDARD AND PRO offers.
  // See changed design in https://jira.corp.adobe.com/browse/ONESIE-44604.
  // Because the temp solution is mainly about avoiding the stacking of FireFly premium offers onto FireFly standard offers
  if (eligibleStandardOffers.length === 0 || eligibleProOffers.length === 0) return;

  // Only show the coachmark if there are available licenses for both STANDARD AND PRO offers.
  if (availableLicenses(eligibleStandardOffers) === 0 || availableLicenses(eligibleProOffers) === 0)
    return;

  // Possibilities the request could stack FireFly offers
  // Check whether there is a FireFly offer in the request.
  accessRequest.products.forEach((product) => {
    if (
      FIREFLY_STANDARD_OFFERS.includes(product.offerId) ||
      FIREFLY_PRO_OFFERS.includes(product.offerId)
    ) {
      // Trigger a "change-product" coachmark
      triggerGainsightCoachmark({
        changePlan: false,
        memberId: accessRequest.userAccountId,
      });
    }
  });
};

export default useFireflyOfferHandler;
